export function trackEvent(eventCategory: string, eventAction: string, eventLabel = '') {
  const gWindow: (typeof window & { dataLayer?: Array<any> }) | undefined =
    typeof window != 'undefined' ? window : undefined;
  if (typeof gWindow != 'undefined' && gWindow.dataLayer) {
    gWindow.dataLayer.push({
      event: 'GAEvent',
      eventCategory,
      eventAction,
      eventLabel,
    });
  }
}

export function sendSearchTags(filters: Filter<any>[]) {
  const gWindow: (typeof window & { dataLayer?: Array<any> }) | undefined =
    typeof window != 'undefined' ? window : undefined;
  if (gWindow && gWindow.dataLayer) {
    if (filters.length) {
      const filterParams: string[] = [];
      filters.forEach((filter) => {
        if (filter.value) {
          if (filter.type === 'dealerID') {
            filterParams.push(`dealerID:${filter.extra.label}`);
          } else {
            const queryString = filter.queryString()?.toString();
            if (queryString) {
              filterParams.push(queryString);
            }
          }
        }
      });
      let filterQueries = filterParams.map((f) => f.split(':')[1]).join('+');
      let categories = [...new Set(filterParams.map((f) => f.split(':')[0]))].join('|');

      if (filterQueries.length) {
        filterQueries = `filter=${filterQueries}`;
        categories = `&category=${categories}`;
      }

      gWindow.dataLayer.push({
        event: 'Site Search',
        eventLabel: 'Site Search Tag',
        url: `/?${filterQueries}${categories}`,
      });
    }
  }
}
