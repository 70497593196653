import PublicEnvironment from '@/stores/PublicEnvironment';

const _stores: Record<string, any> = {};

function getOrCreateStore<T>(fnName: string, Store: Newable<T>): (initialState?: Record<string, any>) => T {
  return (initialState?: Record<string, any>) => {
    // Always create new stores on server
    if (typeof window === 'undefined' || PublicEnvironment.get('ENV') === 'test' || process.env.NODE_ENV === 'test')
      return new Store(initialState);
    // If store has not been created, create a new instance
    if (!_stores[fnName]) _stores[fnName] = new Store(initialState);
    return _stores[fnName];
  };
}

export default getOrCreateStore;
