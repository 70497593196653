import { capitalize, groupBy, uniq } from 'lodash';

import { DRIVE_TYPES } from '@/components/VehicleDetailsPage/constants';

import placeholderImage from '@/images/vehicle-placeholder.png';

import { getPrettyPriceFormat, toTitleCase } from './utils';

/**
 * Format comments, returns `''` on no undefined comments
 */
export const getPrettyComments = (stockItem: StockItem) => {
  if (stockItem.comments) {
    const punctuationRegex = /(\.|!|\?)/;
    let output = stockItem.comments;

    // Remove all-caps
    if (stockItem.comments.toUpperCase() === stockItem.comments) {
      output = output
        .split(punctuationRegex)
        .map((c) => capitalize(c))
        .join('');
    }

    // Remove trailing newlines
    output = output.replace(/\n+$/, '');

    // Add final punctuation if nothing exists
    if (!output.slice(-1).match(punctuationRegex)) {
      output += '.';
    }

    return output
      .replace('. .', '.') // Remove repeated full-stops
      .replace(/(\w)\s+\./, '$1.') // Remove spaces at the end of a sentence
      .replace(/([^\n])\n([^\n])/, '$1\n\n$2') // Double up all single newlines
      .replace('**', '\n\n**') // Move double-asterisks to new line
      .replace(/(\w)\*(\w)/, '$1* $2') // Space single-asterisks correctly
      .replace(/,+[.]?$/, '.'); // Replace ending comma with a full stop
  }

  return '';
};

/**
 * Slices & appends 'M' to 7 digit prices, returns POA on empty/0 price
 */
export const getPrettyPrice = (stockItem: StockItem) => {
  return getPrettyPriceFormat(stockItem.price);
};

/**
 * Formats milage to `{mileage}km`, returns `N/A` on missing mileage
 */
export const getPrettyMileage = (stockItem: StockItem) => {
  if (typeof stockItem.mileage !== 'undefined') {
    return stockItem.mileage.toLocaleString().split('.')[0] + 'km';
  }

  return 'N/A';
};

export const getMedTitle = (stockItem: StockItem) => {
  return [stockItem.year, stockItem.make, stockItem.model, stockItem.badge].filter((v) => v).join(' ');
};

export const getEngineDetailsString = (stockItem: StockItem) => {
  const parts = [];
  if (stockItem.cylinders) {
    parts.push(`${stockItem.cylinders} cylinder`);
  }

  if (stockItem.engineCapacity) {
    parts.push(`${stockItem.engineCapacity}L`);
  }

  return parts.join(' ');
};

export const getSimpleTransmission = (stockItem: StockItem) => {
  return stockItem.transmission?.toLowerCase()?.includes('manual') ? 'Manual' : 'Automatic';
};

export const getShortTransmission = (stockItem: StockItem) => {
  return stockItem.transmission?.toLowerCase().includes('manual') ? 'Manual' : 'Auto';
};

export const getFuelTypeFeature = (stockItem: StockItem) => {
  return isElectricVehicle(stockItem) ? 'Plug-in electric' : stockItem.fuelType?.toLowerCase();
};

export const getFuelType = (stockItem: StockItem) => {
  return isElectricVehicle(stockItem) ? 'electric' : 'petrol';
};

export const getFuelEfficiency = (stockItem: StockItem) => {
  return isElectricVehicle(stockItem) ? 'Per charge' : 'Economy';
};

export const getFuelEfficiencyIcon = (stockItem: StockItem) => {
  return isElectricVehicle(stockItem) ? 'recharging' : 'droplet';
};

export const getFuelEfficiencyValue = (stockItem: StockItem) => {
  return isElectricVehicle(stockItem)
    ? `${stockItem.fuelEfficiency}kWh / 100km`
    : `${stockItem.fuelEfficiency}L / 100km`;
};

export const getMakeModel = (stockItem: StockItem) => {
  return `${toTitleCase(stockItem.make)} ${toTitleCase(stockItem.model)}`;
};

export const getGovernmentChargesString = (stockItem: StockItem) => {
  if (!stockItem.price) return null;
  return stockItem.priceType === 'EGC' ? 'e.g.c' : 'Drive away';
};

export const getPriceDisclaimerLabel = (stockItem: StockItem) => {
  if (!stockItem.price) return undefined;
  return stockItem.priceType === 'EGC' ? 'E.G.C Price Disclaimer' : 'Drive Away Price Disclaimer';
};

export const getPriceDisclaimerContent = (
  stockItem: StockItem,
  egcPriceDisclaimer: string,
  driveAwayPriceDisclaimer: string,
) => {
  if (!stockItem.price) return '';
  return stockItem.priceType === 'EGC' ? egcPriceDisclaimer : driveAwayPriceDisclaimer;
};

export const getRawFuelType = (stockItem: StockItem) => {
  return `${stockItem.fuelType}`;
};

export const getFeatureGroups = (stockItem: StockItem) => {
  return uniq(stockItem.features?.filter((f): f is Feature => !!f).map((f) => f.FeatureGroup));
};

export const getFeaturesFor = (stockItem: StockItem, featureGroup: string) => {
  const features = groupBy(
    stockItem.features?.filter((f): f is Feature => f?.FeatureGroup === featureGroup),
    (f) => f?.FeatureName,
  );
  return Object.keys(features).map((name) => ({ name, features: features[name].map((f) => f?.Value) }));
};

export const hasFeature = (stockItem: StockItem, featureGroup: string, featureName: string, value: string) => {
  const featureType = getFeaturesFor(stockItem, featureGroup).find((f) => f.name === featureName);
  if (featureType) {
    return featureType.features.filter((f) => f?.includes(value)).length > 0;
  }
  return false;
};

export const getSlugWithoutStockNo = (stockItem: StockItem) => {
  return stockItem.slug.split('-').slice(0, -1).join('-');
};

export const extractStockPhotos = (stockItem: StockItem): string[] => {
  if (stockItem.photos) {
    return stockItem.photos.filter((f): f is string => !!f);
  }
  return [];
};

export const getStockImageOrPlaceholder = (stockItem: StockItem): string => {
  const photos = extractStockPhotos(stockItem);
  if (photos.length) {
    return photos[0];
  }
  return placeholderImage.src;
};

export const extractStockItemWithPrice = (stockItem?: Nullable<StockItem>): StockItemWithPrice | null => {
  if (stockItem && isStockItemWithPrice(stockItem)) return stockItem;
  return null;
};

// Formula: https://autoleaguex.atlassian.net/wiki/spaces/MMP/pages/1725202433/Vehicle+Licence+Duty
export const calculateVehicleDuty = (stockItem: StockItem) => {
  if (isStockItemWithPrice(stockItem)) {
    if (stockItem.price <= 25000) return (stockItem.price * 2.75) / 100;
    if (stockItem.price > 50000) return (stockItem.price * 6.5) / 100;
    const r = 2.75 + (stockItem.price - 25000) / 6666.66;
    return Math.round(stockItem.price * r) / 100;
  }
  return 0;
};

// Simple typeguard for StockItemWithPrice type
const isStockItemWithPrice = (stockItem: StockItem): stockItem is StockItemWithPrice => {
  return (stockItem as StockItemWithPrice).price !== undefined;
};

const isElectricVehicle = (stockItem: StockItem) => {
  return !!stockItem.fuelType?.toLowerCase().includes('electric');
};

export const getDriveType = (stockItem: StockItem) => {
  const inputDriveType = stockItem?.driveType?.toUpperCase();
  const matchedDriveType = DRIVE_TYPES.find((type) => type.toUpperCase() === inputDriveType);
  return matchedDriveType || null;
};
