import slugify from 'slugify';
import { Client } from 'urql';

import { Filter } from '@/models';

import { statesLong } from './AddressFormat';
import { loadFiltersFunction } from './Stock/functions';

export const regionStandard = (region: string) => slugify(region.toLowerCase().replace('-region', ''));
export const stateStandard = (state: string) => slugify(statesLong?.[state.toLowerCase()] || state.toLowerCase());

export const removeDash = (str?: string) => str?.replace(/-/g, ' ') || '';

export const getBody = (maybeBody: string, filterStore: StockFiltersFragment) =>
  filterStore.body?.find((bodyType) => removeDash(bodyType?.toUpperCase()) === removeDash(maybeBody?.toUpperCase())) ||
  '';

// checks against directoryStore.filterStore includes the make given
export const isMake = (maybeMake: string, filterStore: StockFiltersFragment) =>
  filterStore.make?.some((make) => removeDash(make?.make?.toUpperCase()) === removeDash(maybeMake?.toUpperCase()));

export const getMake = (maybeMake: string, filterStore: StockFiltersFragment) =>
  filterStore.make?.find(
    (makeType) => removeDash(makeType?.make?.toUpperCase()) === removeDash(maybeMake?.toUpperCase()),
  )?.make || '';

// Returns a boolean of whether `maybeCondition` is a condition
export const isCondition = (maybeCondition: string) => ['NEW', 'USED', 'DEMO'].includes(maybeCondition?.toUpperCase());

export const isModel = (maybeModel: string, filterStore: StockFiltersFragment) =>
  filterStore?.model?.some(
    (modelType) => removeDash(modelType?.model?.toUpperCase()) === removeDash(maybeModel?.toUpperCase()),
  );

export const getModel = (maybeModel: string, filterStore: StockFiltersFragment) =>
  filterStore?.model?.find(
    (modelType) => removeDash(modelType?.model?.toUpperCase()) === removeDash(maybeModel?.toUpperCase()),
  )?.model || '';

// Returns a boolean of whether `maybeState` is a state based on the only two states available
export const isState = (maybeState: string) =>
  ['WESTERN AUSTRALIA', 'VICTORIA', 'QUEENSLAND'].includes(removeDash(maybeState));

// Returns a boolean of whether `maybeCity` is a city (requires state to be set to filterSEO obj)
export const isCity = (maybeCity: string, filterSEO: UrlStockFilter) => {
  if (maybeCity.toLowerCase() == 'perth' && filterSEO.state == 'Western Australia') {
    return true;
  }
  if (maybeCity.toLowerCase() == 'melbourne' && filterSEO.state == 'Victoria') {
    return true;
  }
  if (maybeCity.toLowerCase() == 'brisbane' && filterSEO.state == 'Queensland') {
    return true;
  }
  return false;
};

// Returns a boolean of whether `maybeSuburb` is a suburb based of dealerCodeToSuburb
export const isSuburb = (maybeSuburb: string, dealerCodeToSuburb: Record<string, Nullable<string>>) =>
  !!Object.keys(dealerCodeToSuburb).filter(
    (key) => cleanCmsSuburb(maybeSuburb) == cleanCmsSuburb(dealerCodeToSuburb?.[key] || ''),
  ).length;

// Returns an array of dealerIds which are in the suburb name given
export const suburbToDealerIds = (suburb: string, dealerCodeToSuburb: Record<string, Nullable<string>>) =>
  Object.keys(dealerCodeToSuburb).filter(
    (key) => cleanCmsSuburb(suburb) == cleanCmsSuburb(dealerCodeToSuburb?.[key] || ''),
  );

// Returns lowercase slugified version of any suburb strings that come from CMS EtherMap
// e.g. 'Essendon Fields, Melbourne   ' -> 'essendon-fields'
export const cleanCmsSuburb = (suburb: string) => suburb?.split(',')?.[0]?.toLowerCase()?.trim()?.replace(' ', '-');

// returns filterStore with model data if make is provided in url correctly
// used in SEO v2 model
export const getModelBaseFilterStore = async (
  pathSegments: string[],
  urqlClient: Client,
  filterStore?: StockFiltersFragment,
) => {
  if (filterStore && (isMake(pathSegments?.[2], filterStore) || isMake(pathSegments?.[3], filterStore))) {
    const make = isMake(pathSegments?.[2], filterStore)
      ? getMake(pathSegments?.[2], filterStore)
      : getMake(pathSegments?.[3], filterStore);
    const makeFilter = new Filter('make', make);
    return await loadFiltersFunction(urqlClient, [makeFilter]);
  }
  return filterStore;
};

// Returns a boolean of whether `maybeCity` is a city
export const validCity = (maybeCity: string) => {
  if (['perth', 'melbourne', 'brisbane'].includes(maybeCity.toLowerCase())) {
    return true;
  }
  return false;
};

export const getStateFromCity = (maybeCity: string) => {
  if (maybeCity.toLowerCase() == 'perth') {
    return 'western australia';
  }
  if (maybeCity.toLowerCase() == 'melbourne') {
    return 'victoria';
  }
  if (maybeCity.toLowerCase() == 'brisbane') {
    return 'queensland';
  }
  return null;
};

export const getCityFromState = (maybeState: string) => {
  if (maybeState.toLowerCase() == 'western australia') {
    return 'Perth';
  }
  if (maybeState.toLowerCase() == 'victoria') {
    return 'Melbourne';
  }
  if (maybeState.toLowerCase() == 'queensland') {
    return 'Brisbane';
  }
  return null;
};
