import { captureException, captureMessage } from '@sentry/nextjs';

export default class Log {
  public static error(message: string, extras: Record<string, any>) {
    const __serialized__ = JSON.stringify(extras, null, 4);
    if (Log.shouldConsoleLog()) {
      console.error({ message, extras, __serialized__ });
    } else {
      captureMessage(message, {
        extra: { ...extras, __serialized__ },
        tags: extras?.tags,
      });
    }
  }

  public static exception(exception: unknown, extras: Record<string, any>) {
    const __serialized__ = JSON.stringify(extras, null, 4);
    if (Log.shouldConsoleLog()) {
      console.error({ exception, extras, __serialized__ });
    } else {
      captureException(exception, {
        extra: { ...extras, __serialized__ },
        tags: extras?.tags,
      });
    }
  }

  public static warning(message: string, extras: Record<string, any>) {
    const __serialized__ = JSON.stringify(extras, null, 4);
    if (Log.shouldConsoleLog()) {
      console.warn({ message, extras });
    } else {
      captureMessage(message, {
        extra: { ...extras, __serialized__ },
        tags: extras?.tags,
      });
    }
  }

  public static shouldConsoleLog() {
    return ['test', 'development'].includes(process.env.NODE_ENV);
  }
}
