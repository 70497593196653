import React from 'react';

interface Props {
  favicon?: Nullable<FaviconAssetFragment>;
}

export default function Favicon({ favicon }: Props) {
  return (
    <>
      <link rel="icon" type="image/png" href={favicon?.url || '/favicons/favicon.png'} />
      <link rel="apple-touch-icon" sizes="57x57" href={favicon?.url57x57 || '/favicons/apple-touch-icon-57x57.png'} />
      <link rel="apple-touch-icon" sizes="60x60" href={favicon?.url60x60 || '/favicons/apple-touch-icon-60x70.png'} />
      <link rel="apple-touch-icon" sizes="72x72" href={favicon?.url72x72 || '/favicons/apple-touch-icon-72x72.png'} />
      <link rel="apple-touch-icon" sizes="76x76" href={favicon?.url76x76 || '/favicons/apple-touch-icon-76x76.png'} />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={favicon?.url114x114 || '/favicons/android-chrome-114x114.png'}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={favicon?.url120x120 || '/favicons/android-chrome-120x120.png'}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={favicon?.url144x144 || '/favicons/android-chrome-144x144.png'}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={favicon?.url152x152 || '/favicons/android-chrome-152x152.png'}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={favicon?.url180x180 || '/favicons/android-chrome-180x180.png'}
      />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon?.url16x16 || '/favicons/favicon-16x16.png'} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon?.url32x32 || '/favicons/favicon-32x32.png'} />
      <link rel="icon" type="image/png" sizes="96x96" href={favicon?.url96x96 || '/favicons/favicon-96x96.png'} />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={favicon?.url192x192 || '/favicons/android-chrome-192x192.png'}
      />
      <meta name="msapplication-TileColor" content="#00B086" />
      <meta name="msapplication-TileImage" content={favicon?.url144x144 || '/favicons/mstile-144x144.png'} />
      <meta name="theme-color" content="#ffffff" />
    </>
  );
}
