import { useMemo } from 'react';

import Filter from '@/models/Filter';
import * as getStores from '@/stores/index';
import { FilterOptionsWithInstances } from '@/typings/api/FilterOptions';

const getKeyNameStore = (fnName: string) => fnName.replace(/^get(.)/, (_, p1: string) => p1.toLowerCase());

export function initStores() {
  const stores: Record<string, any> = {};
  for (const fnName in getStores) {
    const storeKeyName = getKeyNameStore(fnName);
    stores[storeKeyName] = getStores[fnName as keyof typeof getStores]();
  }

  return { ...stores } as unknown as Stores;
}

export function useStores(initialState: any) {
  const stores = useMemo(() => {
    const stores: Record<string, any> = {};
    for (const fnName in getStores) {
      const storeKeyName = getKeyNameStore(fnName);
      const initialProps = initialState ? JSON.parse(initialState[storeKeyName]) : undefined;
      switch (storeKeyName) {
        case 'directoryStore':
          stores['directoryStore'] = reserializeDirectoryStore(getStores['getDirectoryStore'](initialProps));
          break;
        default:
          stores[storeKeyName] = getStores[fnName as keyof typeof getStores](initialProps);
          break;
      }
    }
    return stores as unknown as Stores;
  }, [initialState]);
  return stores;
}

export function serializeStores(stores: Stores) {
  const serialized: Record<string, any> = {};
  Object.entries(stores).forEach(([k, store]) => {
    serialized[k] = JSON.stringify(store);
  });
  return serialized;
}

/**
 * Since we JSON.stringify the directoryStore, objects with functions get removed.
 *
 * @param directoryStore
 * @return directoryStore
 */
export function reserializeDirectoryStore(directoryStore: DirectoryStore): DirectoryStore {
  directoryStore.filters = directoryStore.filters
    .map((f: Filter) => new Filter(f.type, f.value, f.extra, f.locked))
    .slice();

  (
    ['make', 'model', 'variant', 'dealerID', 'colour', 'features'] satisfies Array<keyof FilterOptionsWithInstances>
  ).forEach((type) => {
    directoryStore.filterOptions[type] = directoryStore.filterOptions[type]?.map(
      (f: Filter) => new Filter(f.type, f.value, f.extra, f.locked),
    );
  });
  directoryStore.recommended = directoryStore.recommended.map((f) => new Filter(f.type, f.value, f.extra)).slice();
  directoryStore.lockedDealerIdFilters = directoryStore.lockedDealerIdFilters
    .map((f) => new Filter(f.type, f.value, f.extra))
    .slice();
  return directoryStore;
}
