/**
 * TODO: Change Record<string, string[]> to Record<string, Set<string>>
 * As a hotfix we are:
 *  - Resetting these properties in init
 *  - calling `uniq` when adding to those attributes that use an array.
 * Otherwise page size gets blown out by this store.
 */
import { computed, flow, makeObservable, observable } from 'mobx';
import { uniq } from 'lodash';
import slugify from 'slugify';
import { Client, OperationResult } from 'urql';

import GraphQLError from '@/errors/GraphQLError';
import { states, statesLong } from '@/lib/AddressFormat';
import { getDealerIdFromInventorySource } from '@/lib/DealershipHelper';
import { regionStandard } from '@/lib/FilterParserUtils';
import Log from '@/lib/Log';
import { getProtocol } from '@/lib/SendRequest';
import { getSlugWithoutStockNo } from '@/lib/StockItemHelper';

import query from './dealershipsMap.graphql';

export default class DealershipsMap {
  // Used in cars page
  dealershipsArray: Array<Dealership> = [];

  // all the vehicle that are currently in progress of finance applications
  vehiclesInProgress: VehicleInProgressType = {};

  constructor(initialProps?: Partial<DealershipsMap>) {
    Object.assign(this, initialProps);

    // istanbul ignore next
    makeObservable(this, {
      dealerCodeToSuburb: computed,
      suburbToDealerCode: computed,
      stateDealerID: computed,
      dealershipsArray: observable,
      mainDealershipsChildren: computed,
      vehiclesInProgress: observable,
      stateToSuburb: computed,
      suburbToState: computed,
    });
  }

  init = flow(function* (this: DealershipsMap, urqlClient: Client, site: string) {
    this.dealershipsArray = [];
    this.vehiclesInProgress = {};

    const { data, error, operation }: OperationResult<DealershipsMapQuery> = yield urqlClient
      .query<DealershipsMapQuery>(query, { site })
      .toPromise();
    if (error) {
      throw new GraphQLError(error, operation);
    }

    try {
      const [protocol, server] = getProtocol();
      const res: Response = yield fetch(`${protocol}//${server}/api/vehicles-in-progress`);
      const resJson: Record<string, any> = yield res.json();

      if (resJson.vehiclesInProgress) {
        this.vehiclesInProgress = resJson.vehiclesInProgress;
      }
    } catch (error) {
      Log.exception('Failed to init vehicles in progress', { error });
    }

    if (!data || !data.locations) {
      Log.error('Failed to init dealerships map', { data });
    } else {
      data.locations
        .filter((location): location is ChildDealershipFragment => !!location && '__typename' in location)
        .forEach((location) => {
          const dealerId = getDealerIdFromInventorySource(location.inventorySource);
          if (location && location.slug && location.title && dealerId) {
            const parentDealerships =
              location.parentDealership?.filter(
                (parentDealership): parentDealership is DealershipsMapMainDealershipFragment =>
                  !!parentDealership && '__typename' in parentDealership,
              ) || null;

            if (parentDealerships && !!parentDealerships.length) {
              const parentDealership = parentDealerships[0]; // the first one is always the parent dealerships
              const suburbName = parentDealership?.address?.parts?.city || null;
              const parentDealershipSlug = parentDealership?.slug;

              const state = parentDealership?.address?.parts?.state;
              const standardState = (!!state && states?.[state.toLowerCase()]) || null;
              const city = parentDealership?.address?.parts?.city;
              const region = parentDealership?.region;
              const lng = parentDealership?.address?.lng;
              const lat = parentDealership?.address?.lat;
              const serviceCentreSlug = parentDealership?.serviceCentre?.[0]?.slug;
              const wholesaleAutoplayDetails = {
                autoplayDealerId: parentDealership?.wholesaleAutoplayDetails?.[0]?.autoplayDealerId ?? null,
                autoplayYardId: parentDealership?.wholesaleAutoplayDetails?.[0]?.autoplayYardId ?? null,
              };

              this.dealershipsArray.push({
                title: location.title,
                slug: location.slug,
                dealeridentifier: dealerId,
                latitude: lat ?? undefined,
                longitude: lng ?? undefined,
                state: state,
                suburbName: suburbName,
                parentDealershipSlug,
                region,
                standardState,
                city,
                serviceCentreSlug,
                wholesaleAutoplayDetails,
              } as Dealership);
            }
          }
        });
    }
  });

  getVehicleStockSeoUrl(vehicle: StockItem) {
    const query = {
      state: this.getVehicleState(vehicle, true).toLowerCase(),
      region: this.getVehicleRegion(vehicle).toLowerCase(),
      suburb: slugify(this.dealerCodeToSuburb?.[vehicle.dealerID]?.toLowerCase() || ''),
      slug: getSlugWithoutStockNo(vehicle),
      dealerId: vehicle.dealerID,
      stock: vehicle.stockNo,
    };

    return Object.values(query).some((seg) => !seg) ? '/cars' : `/cars/view/${Object.values(query).join('/')}`;
  }

  getVehicleState(vehicle: StockItem, long = false) {
    const state =
      Object.keys(this.stateDealerID).find((state) => this.stateDealerID[state].includes(vehicle.dealerID)) || '';
    if (long) return statesLong?.[state.toLowerCase()] || '';
    return state;
  }

  getVehicleRegion(vehicle: StockItem) {
    const region = Object.keys(this.regionDealerID).find((state) =>
      this.regionDealerID[state].includes(vehicle.dealerID),
    );
    return region ? regionStandard(region) : '';
  }

  get suburbToDealerCode() {
    const suburbToDealerCodeResult: Record<string, string[]> = {};
    this.dealershipsArray.forEach((dealership: Dealership) => {
      const { suburbName, dealeridentifier } = dealership;
      if (!suburbName || !dealeridentifier) {
        return;
      }
      suburbToDealerCodeResult[suburbName] = uniq([
        ...(suburbToDealerCodeResult?.[suburbName] || []),
        dealeridentifier,
      ]);
    });
    return suburbToDealerCodeResult;
  }

  get dealerCodeToSuburb() {
    const dealerCodeToSuburbResult: Record<string, Nullable<string>> = {};
    this.dealershipsArray.forEach((dealership: Dealership) => {
      const { suburbName, dealeridentifier } = dealership;
      if (!suburbName || !dealeridentifier) {
        return;
      }
      dealerCodeToSuburbResult[dealeridentifier] = suburbName;
    });
    return dealerCodeToSuburbResult;
  }

  get mainDealershipsChildren() {
    const mainDealershipsChildrenResult: Record<string, string[]> = {};
    this.dealershipsArray.forEach((dealership: Dealership) => {
      const { parentDealershipSlug, dealeridentifier } = dealership;
      if (!parentDealershipSlug || !dealeridentifier) {
        return;
      }
      mainDealershipsChildrenResult[parentDealershipSlug] = uniq([
        ...(mainDealershipsChildrenResult?.[parentDealershipSlug] || []),
        dealeridentifier,
      ]);
    });
    return mainDealershipsChildrenResult;
  }

  get regionDealerID() {
    const regionDealerIDResult: Record<string, string[]> = {};
    this.dealershipsArray.forEach((dealership: Dealership) => {
      const { region, dealeridentifier } = dealership;
      if (!region || !dealeridentifier) {
        return;
      }
      regionDealerIDResult[region] = uniq([...(regionDealerIDResult?.[region] || []), dealeridentifier]);
    });
    return regionDealerIDResult;
  }

  get stateToSuburb() {
    const stateToSuburbResult: Record<string, string[]> = {};
    this.dealershipsArray.forEach((dealership: Dealership) => {
      const { standardState, city } = dealership;
      if (!standardState || !city) {
        return;
      }
      stateToSuburbResult[standardState] = uniq([...(stateToSuburbResult?.[standardState] || []), city]);
    });
    return stateToSuburbResult;
  }

  get suburbToState() {
    const suburbToStateResult: Record<string, string> = {};
    this.dealershipsArray.forEach((dealership: Dealership) => {
      const { standardState, city } = dealership;
      if (!standardState || !city) {
        return;
      }
      // Map each suburb to its state, assuming a suburb belongs to only one state
      suburbToStateResult[city] = standardState;
    });
    return suburbToStateResult;
  }

  get stateDealerID() {
    const stateDealerIDResult: Record<string, Array<string>> = {};
    this.dealershipsArray.forEach((dealership: Dealership) => {
      const { standardState, dealeridentifier } = dealership;
      if (!standardState || !dealeridentifier) {
        return;
      }
      stateDealerIDResult[standardState] = uniq([...(stateDealerIDResult?.[standardState] || []), dealeridentifier]);
    });
    return stateDealerIDResult;
  }

  getContextualStockContentLocation = (dealerIds: string[]) => {
    let state: Nullable<string> = null;
    let suburbs: string[] = [];
    if (this.stateDealerID['WA']?.some((dealerId: string) => dealerIds.includes(dealerId))) {
      state = 'western-australia';
    }
    if (this.stateDealerID['VIC']?.some((dealerId: string) => dealerIds.includes(dealerId))) {
      state = 'victoria';
    }
    if (this.stateDealerID['QLD']?.some((dealerId: string) => dealerIds.includes(dealerId))) {
      state = 'queensland';
    }

    if (
      !this.stateDealerID['WA']?.every((dealerId: string) => dealerIds.includes(dealerId)) &&
      !this.stateDealerID['VIC']?.every((dealerId: string) => dealerIds.includes(dealerId)) &&
      !this.stateDealerID['QLD']?.every((dealerId: string) => dealerIds.includes(dealerId))
    ) {
      suburbs = uniq(dealerIds.map((d: string) => this.dealerCodeToSuburb[d]) as string[]);
    }

    if (state && suburbs.length === 1) {
      return [state, suburbs[0]];
    }

    if (state && suburbs.length === 0) {
      return [state, null];
    }

    return [null, null];
  };
}
