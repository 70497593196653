import { computed, flow, isFlowCancellationError, makeAutoObservable } from 'mobx';
import { CancellablePromise } from 'mobx/dist/internal';
import base64url from 'base64url';
import { pack, unpack } from 'jsonpack';
import { capitalize, flatten, isEqual, orderBy, unionWith, xorWith } from 'lodash';
import { deflate, inflate } from 'pako';
import { Client } from 'urql';

import sortOptions from '@/components/SearchDirectory/SortOptions';
import { sendSearchTags } from '@/lib/GA';
import { extractItems } from '@/lib/GraphQLHelper';
import { isNetworkError } from '@/lib/isNetworkError';
import Log from '@/lib/Log';
import { loadFiltersFunction, loadFunction, loadMakeFiltersFunction } from '@/lib/Stock/functions';
import { Filter } from '@/models';
import { FilterOptionsWithInstances } from '@/typings/api/FilterOptions';

const locationFilters = ['dealerID'];

export default class DirectoryStore {
  loading = false;
  hasLoadedOnce = false;
  similarHasLoadedOnce = false;
  results: Array<StockItem> = [];
  similarResults: Array<StockItem> = [];
  currentPage = 1;
  limit = 37;
  total = 0;
  similarResultsTotal = 0;
  similarResultsOffset = 0;
  sortMethod: SortMethod = sortOptions[0].sortMethod;
  sortLocation: SortLocation = null;
  recommended: Array<Filter> = [];
  clearEvent = false;
  weeklyPrice = false;
  urqlClient: Nullable<Client> = null;
  hydrated = false;

  regionLocationFilterActive = false;
  suburbLocationFilterActive = false;

  similarCars = true;

  urlStockFilter: UrlStockFilter = {};

  sortLocationStockLoading = false;
  sortLocationStockTotal = 0;

  scrollToTop = false;

  makeFilterSelected: Maybe<Filter> = null;
  popularTypes: Maybe<string>[] = [];
  popularModels: Maybe<Filter>[] = [];
  popularHasLoadedOnce = false;

  popStateUpdating = false;

  pushedDownHashtag: Nullable<string> = null;

  constructor(initialProps?: Partial<DirectoryStore>) {
    Object.assign(this, initialProps);

    makeAutoObservable(this, {
      allUrlParams: computed,
      allResultsLoaded: computed,
      allSimilarResultsLoaded: computed,
      filterQueryParam: computed,
      allFilters: computed,
      filterLength: computed,
      unlockedFilterLength: computed,
      ignoreLocationFilters: computed,
      filtersLabel: computed,
      isStockPage: computed,
    });
  }

  init = flow(function* (
    this: DirectoryStore,
    dealerships: Dealership[],
    beforeInit?: (directoryStore: DirectoryStore) => Promise<void>,
  ) {
    this.dealerships = dealerships;

    yield beforeInit?.(this);
    yield this.loadFilterOptions(this.allFilters);
    yield this.filterStockList(this.filters, this.currentPage);
    yield this.loadMakeFilterOptions(this.makeFilterOnce);

    this.hydrated = true;
  });

  private reloadStockAndFilterOptionsPromise: CancellablePromise<void> | undefined = undefined;

  private _reloadStockAndFilterOptions = flow(function* (this: DirectoryStore, pageReset = true) {
    // reset page to 1 when any filter change
    if (pageReset) {
      this.currentPage = 1;
    }
    yield Promise.all([
      this.filterStockList(this.filters, this.currentPage),
      this.loadFilterOptions(this.allFilters),
      this.loadMakeFilterOptions(this.makeFilterOnce),
    ]);
    this.similarHasLoadedOnce = false;
  });

  private reloadStockAndFilterOptions = async (pageReset = true) => {
    try {
      this.reloadStockAndFilterOptionsPromise?.cancel?.();
      await (this.reloadStockAndFilterOptionsPromise = this._reloadStockAndFilterOptions(pageReset));
    } catch (e) {
      if (!(e instanceof Error)) {
        Log.error('DirectoryStore: Unexpected type of error', { error: e });
        return;
      }

      if (isFlowCancellationError(e) || isNetworkError(e)) {
        console.debug('DirectoryStore: Cancelled reloadStockAndFilterOptions()');
        return;
      }

      Log.error('DirectoryStore: reloadStockAndFilterOptions flow error', e);
    }
  };

  private reloadStock = async () => {
    this.results = [];
    await this.loadStockList(false, this.currentPage);
  };

  handleRecommendedFilters = (recommended: RecommendedFilters) => {
    Object.entries(recommended).forEach(([k, v]) => {
      if (v) {
        switch (k) {
          case 'listingType':
            recommended.listingType.forEach((l) => {
              this.recommended.push(new Filter('listingType', capitalize(l), {}, true));
            });
            break;
          case 'bodyStyle':
            recommended.bodyStyle.forEach((b) => {
              this.recommended.push(new Filter('body', b, {}, true));
            });
            break;
          case 'maxPrice':
            if (recommended.maxPrice !== '0') {
              this.recommended.push(new Filter('price', parseInt(recommended.maxPrice, 10)));
            }
            break;
          default:
            break;
        }
      }
    });
  };

  get allUrlParams() {
    return [...this.filters, this.currentPage, ...this.sortMethod, this.regionLocationFilterActive];
  }

  get allResultsLoaded() {
    return this.hasLoadedOnce && this.results.length >= this.total && !this.loading;
  }

  get allSimilarResultsLoaded() {
    return this.hasLoadedOnce && this.similarResults.length >= this.similarResultsTotal && !this.loading;
  }

  get totalPages() {
    return this.hasLoadedOnce ? Math.ceil(this.total / this.limit) : 0;
  }

  setCurrentPage = (page: number) => {
    this.currentPage = page;
  };

  changeCurrentPage = (page: number) => {
    this.currentPage = page;
    this.scrollToTop = true;
    this.reloadStock();
  };

  changeSortMethod = (sortMethod: SortMethod) => {
    this.sortMethod = sortMethod;
    this.reloadStock();
  };

  changeSortLocation = (sortLocation: SortLocation) => {
    this.sortLocation = sortLocation;
    this.reloadStock();
  };

  private loadStockListPromise: CancellablePromise<void> | undefined = undefined;
  loadStockList = async (append = false, page = 1) => {
    if (this.hasLoadedOnce) this.loadStockListPromise?.cancel?.();

    this.loadStockListPromise = this._loadStockList(append, page);
    await this.loadStockListPromise.catch((e) => {
      if (isFlowCancellationError(e) || isNetworkError(e)) {
        console.debug('DirectoryStore: Cancelled loadStockList()');
      } else {
        Log.error('DirectoryStore: loadStockList flow error', e);
      }
    });
  };

  private _loadStockList = flow(function* f(this: DirectoryStore, append = false, page = 1) {
    if (!this.urqlClient) {
      throw new Error('urqlClient is missing');
    }

    this.loading = true;

    const sortWeight = this.sortMethod === 'recommended' ? this.recommended : [];

    const params: StockRequestParams = {
      filter: this.allFilters,
      weight: sortWeight,
      sort: this.sortMethod,
      limit: this.limit,
      offset: 0,
      similar: false,
      sortByDealerIds: this.sortLocation?.dealerIds,
    };

    if (this.pushedDownHashtag) {
      params.pushedDownHashtag = this.pushedDownHashtag;
    }

    if (page > 1) {
      params.limit = this.limit;
      params.offset = (page - 1) * this.limit;
    }

    const json: StockListFragment = yield loadFunction(this.urqlClient, params);
    if (json.stock) {
      const stock = extractItems<PartialStockItemFragment>(json.stock) ?? [];
      this.total = json?.total;
      this.results = append ? this?.results?.concat(stock) : stock;
    } else {
      this.total = 0;
      this.results = [];
    }

    if (!append && params.filter?.length) {
      sendSearchTags(params.filter);
    }

    this.loading = false;
    this.hasLoadedOnce = true;
  });

  loadSortLocationStock = async (radiusDealerIds: string[]) => {
    if (!this.urqlClient) {
      throw new Error('urqlClient is missing');
    }

    if (radiusDealerIds.length === 0) {
      this.setSortLocationStockTotal(0);
      return;
    }

    this.setSortLocationStockLoading(true);

    let sortLocationFilters: Filter[] = [];
    // if dealer filter visible, remove those filters does not existed on sort dealer ids
    if (this.hasFiltersFor('dealerID')) {
      const activeDealers = this.filters.filter((f) => f.type === 'dealerID').map((d) => d.value);

      if (radiusDealerIds.some((dealerId: string) => activeDealers.includes(dealerId))) {
        sortLocationFilters = this.filters.filter(
          (filter) => filter.type !== 'dealerID' || radiusDealerIds.includes(filter.value as string),
        );
      } else {
        // there aren't any dealer existed on radius dealer list.
        this.setSortLocationStockTotal(0);
        this.setSortLocationStockLoading(false);
        return;
      }
    } else {
      // append dealer filter same with sort dealers
      const dealerFilter: Filter[] = radiusDealerIds.map((dealerId) => new Filter('dealerID', dealerId)) || [];
      sortLocationFilters = this.filters.concat(dealerFilter);
    }

    const params: StockRequestParams = {
      filter: sortLocationFilters,
      weight: [],
      sort: this.sortMethod,
      limit: 1,
      offset: 0,
      similar: false,
    };

    const json: StockListFragment = await loadFunction(this.urqlClient, params);
    if (json.stock) {
      this.setSortLocationStockTotal(json?.total);
    } else {
      this.setSortLocationStockTotal(0);
    }

    this.setSortLocationStockLoading(false);
  };

  loadStockPage() {
    if (!this.urqlClient) {
      throw new Error('urqlClient is missing');
    }

    this.loadStockList(false, this.currentPage);
  }

  filterStockList = async (filters: Filter[] = [], page = 1) => {
    this.results = [];

    this.filters = filters;
    await this.loadStockList(false, page);
  };

  setWeeklyPrice = (value: boolean) => {
    this.weeklyPrice = value;
  };

  setRegionLocationFilterActive = (value: boolean) => {
    this.regionLocationFilterActive = value;
  };

  setSuburbLocationFilterActive = (value: boolean) => {
    this.suburbLocationFilterActive = value;
  };

  setUrlStockFilter = (newUrlStockFilter: UrlStockFilter) => {
    this.urlStockFilter = newUrlStockFilter;
  };

  setSortLocationStockLoading = (value: boolean) => {
    this.sortLocationStockLoading = value;
  };

  setSortLocationStockTotal = (value: number) => {
    this.sortLocationStockTotal = value;
  };

  // FILTERSTORE
  filters: Array<Filter> = [];
  filterOptions: FilterOptionsWithInstances = {
    body: [],
    colour: [],
    features: [],
    transmission: [],
    year: [],
    make: [],
    model: [],
    seats: [],
    variant: [],
    fuelType: [],
    dealerID: [],
    StockNo: [],
    priceRanges: [],
    yearRanges: [],
    driveType: [],
  };
  rawFilterOptions: StockFiltersFragment | null = null;
  filterUpdateTimestamp = -1;
  maxPriceVisibleOnFilter = 150000;
  maxPricePlaceholder = '150K+';
  maxMileageVisibleOnFilter = 250000;
  maxMileagePlaceholder = '250,000+';

  dealerships: Dealership[] = []; // Assign in parent class
  filterOptionsLoaded = false;

  /** stores locked dealerID filters for usedCarPlatforms. Should be set during GSP */
  lockedDealerIdFilters: Array<Filter<string>> = [];

  get filterQueryParam(): Nullable<string> {
    // only convert unlocked filters into param
    const unlockedfilters = this.filters.filter((f) => !f.locked);
    if (unlockedfilters.length <= 0) return null;
    try {
      const jsonString = JSON.stringify(unlockedfilters);
      const packed = deflate(pack(jsonString));
      // Uint8Array === nodejs' Buffer (Buffer is subclass)
      const res = base64url.encode(packed as Buffer);
      return res;
    } catch (e) {
      console.error('DirectoryStore: get filterQueryParam error', e);
      return null;
    }
  }

  parseFilterQueryParam = (param: string) => {
    try {
      const buff = base64url.toBuffer(param);
      const inflated = inflate(buff, { to: 'string' });
      const unpacked = unpack<MinimalFilterObject<string | number | number[] | boolean>[]>(inflated);

      return unpacked;
    } catch (e) {
      console.error('DirectoryStore: parseFilterQueryParam() error', e);
      return null;
    }
  };

  get allFilters() {
    let filters = this.filters;

    const prestigeFilters = this.getFiltersFor('prestigeDealerID');
    if (prestigeFilters.length) {
      const prestigeFiltersIds = flatten(
        prestigeFilters.map((f) => `${f.value}`.split(',').map((v) => new Filter('dealerID', v))),
      );
      filters = filters.filter((filter) => filter.type !== 'dealerID').concat(prestigeFiltersIds);
    }

    // adjust dealer filter if radiusDealerIds available
    if (this.sortLocation?.radiusDealerIds) {
      const radiusDealerIds = this.sortLocation.radiusDealerIds;
      if (radiusDealerIds.length > 0) {
        // if dealer filter visible, remove those filters does not existed on sort dealer ids
        if (this.hasFiltersFor('dealerID')) {
          const activeDealers = this.filters.filter((f) => f.type === 'dealerID').map((d) => d.value);

          if (radiusDealerIds.some((dealerId: string) => activeDealers.includes(dealerId))) {
            filters = filters.filter(
              (filter) => filter.type !== 'dealerID' || radiusDealerIds.includes(filter.value as string),
            );
          } else {
            // there aren't any dealer existed on radius dealer list.
            filters = this.filters
              .filter((filter) => filter.type !== 'dealerID')
              .concat(new Filter('dealerID', 'empty-dealer'));
          }
        } else {
          // append dealer filter same with sort dealers
          const dealerFilter: Filter[] = radiusDealerIds?.map((dealerId) => new Filter('dealerID', dealerId)) || [];
          filters = filters.concat(dealerFilter);
        }
      }
    }

    // If no dealerIDs are set in this.allFilters, check lockedDealerIdFilters
    filters = filters.some((f) => f.type === 'dealerID') ? filters : [...filters, ...this.lockedDealerIdFilters];

    return filters;
  }

  get makeFilterOnce() {
    if (this.getFiltersFor('make').length === 1) {
      return this.getFiltersFor('make')[0];
    }

    return null;
  }

  get filterQuery() {
    return this.allFilters.map((filter) => filter.queryString()).join(',');
  }

  loadFilterOptions = flow(function* f(this: DirectoryStore, filters: Array<Filter> = []) {
    if (!this.urqlClient) {
      throw new Error('urqlClient is missing');
    }
    this.filterOptionsLoaded = false;

    const filterUpdateTimestamp = Date.now();
    const options: StockFiltersFragment = yield loadFiltersFunction(this.urqlClient, filters);
    this.rawFilterOptions = options;

    const optionsWithInstances = {
      ...options,
      make: orderBy(options?.make, ['count', 'make'], ['desc', 'asc'])?.map(
        (m) => new Filter('make', m?.make, { count: m?.count }),
      ),
      model:
        options?.model?.map(
          (m) => new Filter('model', m?.model, { make: m?.make, model: m?.model, count: m?.count }),
        ) || [],
      variant:
        options?.variant?.map(
          (m) =>
            new Filter('variant', m?.variant, {
              make: m?.make,
              model: m?.model,
              variant: m?.variant,
              count: m?.count,
            }),
        ) || [],
      dealerID: this.dealerships
        .filter((d: Dealership) => d.title && d.dealeridentifier && options.dealerID?.includes(d.dealeridentifier))
        .map(
          (d: Dealership) =>
            new Filter({
              type: 'dealerID',
              value: d.dealeridentifier!,
              extra: { label: d.title! },
            }),
        ),
      colour: orderBy(options.colour, ['count', 'colour'], ['desc', 'asc'])
        .map((m) => m?.colour)
        .filter((colour): colour is string => !!colour)
        .map((colour) => new Filter('colour', colour)),
    };

    // Only update filterOptions if the timestamp is newer than the stored value.
    // Avoids race conditions on the reaction
    if (filterUpdateTimestamp >= this.filterUpdateTimestamp) {
      this.filterUpdateTimestamp = filterUpdateTimestamp;
      this.filterOptions = Object.assign({}, this.filterOptions, optionsWithInstances);
      this.filterOptionsLoaded = true;
    }
  });

  loadMakeFilterOptions = flow(function* f(this: DirectoryStore, makeFilter: Maybe<Filter>) {
    if (!this.urqlClient) {
      throw new Error('urqlClient is missing');
    }

    if (this.makeFilterSelected !== makeFilter || this.popularHasLoadedOnce === false) {
      // save current make selected
      this.makeFilterSelected = makeFilter;

      const data: MakeSuggestFilterFragment = yield loadMakeFiltersFunction(this.urqlClient, makeFilter);
      this.popularTypes = data.body;
      this.popularModels =
        data.model?.map((m) => new Filter('model', m?.model, { make: m?.make, model: m?.model, count: m?.count })) ||
        [];
      this.popularHasLoadedOnce === true;
    }
  });

  loadFeatureFilters = (features: { featureTitle: string; values: string }[]) => {
    const featureFilters = features?.map((f) => new Filter('features', f.values, { label: f.featureTitle })) || [];
    this.filterOptions.features = featureFilters;
  };

  addFilters = async (filtersToAdd: Filter[], pageReset = true) => {
    this.filters = unionWith(filtersToAdd, this.filters, Filter.isEqual);
    await this.reloadStockAndFilterOptions(pageReset);
  };

  removeFilters = async (type: string) => {
    this.filters = this.filters.filter((f) => f.type !== type);
    await this.reloadStockAndFilterOptions();
    return this.filters;
  };

  toggleFilter = async (filter: Filter) => {
    let activeFilters = xorWith([filter], this.filters, Filter.isEqual);

    // If the filter was removed and it was a make or model filter
    if (!activeFilters.includes(filter)) {
      if (filter.type === 'make') {
        activeFilters = activeFilters.filter((f) => f.extra.make !== filter.value);
      } else if (filter.type === 'model') {
        activeFilters = activeFilters.filter((f) => f.type !== 'variant' || f.extra.model !== filter.value);
      }
    }

    // if any filter is added or removed we should scroll to top for new results
    this.scrollToTop = true;
    this.filters = activeFilters;
    await this.reloadStockAndFilterOptions();
  };

  replaceFilters = async (filters: Filter[], type: string) => {
    this.filters = this.filters.filter((f) => f.type !== type).concat(filters);
    await this.reloadStockAndFilterOptions();
  };

  // Replaces all filters for the given type, leaving others untouched
  replaceFilterWith = async (filter: Filter) => {
    const activeFilters = this.filters.filter((f) => f.type !== filter.type);
    activeFilters.push(filter);
    this.filters = activeFilters;
    await this.reloadStockAndFilterOptions();
  };

  clearFilters = async () => {
    this.triggerClearEvent();
    // Keep only locked filters
    const lockedFilters = this.filters.filter((f) => f.locked === true);

    /* istanbul ignore else */
    if (!isEqual(this.filters.slice(), lockedFilters)) {
      this.filters = lockedFilters;
      await this.reloadStockAndFilterOptions();
    }
  };

  setPopStateProgressStatus = (value: boolean) => {
    this.popStateUpdating = value;
  };

  popStateUpdate = async (filterString: Nullable<string>, page: number, sort: SortMethod) => {
    // Alway update page number and sort method
    this.currentPage = page;
    this.sortMethod = sort;

    if (filterString === this.filterQueryParam) {
      // There no filter change, refesh stock with page and sort method
      await this.reloadStock();
    } else {
      // Get all locked filters
      const lockedFilters = this.filters.filter((f) => f.locked === true);
      const rawFilterArr = filterString ? this.parseFilterQueryParam(filterString) : [];
      if (rawFilterArr && Array.isArray(rawFilterArr)) {
        // Merge filters from URL and locked filters
        const newFilters = [...lockedFilters, ...rawFilterArr.map((t) => new Filter(t))];
        this.filters = newFilters;
      } else {
        // There no filter appear on query, reset to locked filters (clear filters)
        this.filters = lockedFilters;
      }

      await this.reloadStockAndFilterOptions(false);
    }

    // clear pop state stutus
    this.setPopStateProgressStatus(false);
  };

  removePriceFilter = async () => {
    const priceFilter = this.filters.filter((f) => f.type === 'price');
    if (priceFilter.length) {
      this.toggleFilter(priceFilter[0]);
    }
    await this.reloadStockAndFilterOptions();
  };

  hasFilter = (type: string, value: string | number | boolean | Filter) => {
    if (value instanceof Filter) {
      return !!this.filters.find((f) => f.type === type && Filter.isEqual(f, value));
    }

    return !!this.filters.find((f) => f.type === type && f.value === value.toString());
  };

  hasFiltersFor = (keyOrKeys: string | Array<string>) => this.getFiltersFor(keyOrKeys).length > 0;

  hasLockedFiltersFor = (keyOrKeys: string | Array<string>) =>
    this.getFiltersFor(keyOrKeys).filter((f) => f.locked).length > 0;

  getFiltersFor = (typeOrTypes: string | Array<string>): Array<Filter> => {
    if (typeOrTypes instanceof Array) {
      return this.filters.filter((f) => typeOrTypes.includes(f.type));
    }

    return this.filters.filter((f) => f.type === typeOrTypes);
  };

  countFiltersFor = (typeOrTypes: string | Array<string>): number => {
    if (typeOrTypes instanceof Array) {
      return this.filters.filter((f) => typeOrTypes.includes(f.type)).length;
    }

    return this.filters.filter((f) => f.type === typeOrTypes).length;
  };

  getContextualStockContent = () => {
    const makeFilters = this.filters?.filter((filter) => !!filter?.type && filter?.type === 'make') || [];
    const makeFilter = makeFilters?.length === 1 ? makeFilters?.[0]?.value?.toString() : null;

    const modelFilters = this.filters?.filter((filter) => !!filter?.type && filter?.type === 'model') || [];
    const modelFilter = modelFilters?.length === 1 ? modelFilters?.[0]?.value?.toString() : null;

    const locationFilters = this.filters?.filter((filter) => !!filter?.type && filter?.type === 'dealerID') || [];
    const bodyFilters = this.filters?.filter((filter) => !!filter?.type && filter?.type === 'body') || [];

    // If filter by make + (state/city or/and body), prioritise the make contextual
    if (this.filters.length - 1 === locationFilters.length + bodyFilters.length && makeFilter) {
      return [makeFilter, null];
    }

    // If filter by make/model + (state/city or/and body), prioritise the make/model contextual
    if (this.filters.length - 2 === locationFilters.length + bodyFilters.length && makeFilter && modelFilter) {
      return [makeFilter, modelFilter];
    }

    return [null, null];
  };

  getContextualStockContentBody = () => {
    const bodyFilters = this.filters?.filter((filter) => !!filter?.type && filter?.type === 'body') || [];
    return bodyFilters.length === 1 ? bodyFilters?.[0]?.value?.toString() : null;
  };

  get filterLength() {
    return this.filters?.length || 0;
  }

  get unlockedFilterLength() {
    return this.filters?.filter((f) => !f.locked).length;
  }

  get ignoreLocationFilters() {
    return this.filters?.filter((f) => !f.locked).filter((f: Filter) => !locationFilters.includes(f.type));
  }

  /**
   * A filter label for the current filters. Suffix it with Car(s) to handle pluralization easily.
   */
  get filtersLabel() {
    const label = [];
    let filter;
    if ((filter = this.getFiltersFor('listingType')).length === 1) {
      label.push(filter[0].value);
    }

    if ((filter = this.getFiltersFor('make')).length === 1) {
      label.push(filter[0].toString());

      if ((filter = this.getFiltersFor('model')).length === 1) {
        label.push(filter[0].toString());
      }
      return label.join(' ');
    }

    if ((filter = this.getFiltersFor('body')).length === 1) {
      label.push(filter[0].toString());
    }

    return label.join(' ');
  }

  get isStockPage() {
    return this.filters?.filter((f) => f.type === 'dealerID' && f.locked).length > 0 || false;
  }

  triggerClearEvent() {
    this.clearEvent = !this.clearEvent;
  }

  setScrollToTopFalse = () => {
    this.scrollToTop = false;
  };
}
