import UrlPattern from 'url-pattern';

interface SiteGroupRouteConfig {
  /**
   * Array of slugified site handles from Craft (e.g. "My Moto" -> "my-moto").
   * These handles belong to a site group.
   */
  siteHandles: Array<string>;

  /**
   * Disabled Routes are any valid pattern/regex supported by url-pattern package.
   * Describes base paths that are enabled for certain sites.
   * @see https://github.com/snd/url-pattern
   */
  allowedRoutes: Array<string>;

  /**
   * Disabled Routes are any valid pattern/regex supported by url-pattern package.
   * Describes base paths that are disabled for certain sites.
   * @see https://github.com/snd/url-pattern
   */
  disallowedRoutes: Array<string>;

  /**
   * References the site group that a handle falls under
   * All sites under a site group will follow same configurations.
   */
  siteGroup: 'mymoto' | 'servicing' | 'usedCarPlatform' | 'buyingCentre' | 'finance';
}

export default class SiteGroupRoutes {
  public static routes: Array<SiteGroupRouteConfig> = [
    {
      siteHandles: ['mymoto'],
      allowedRoutes: ['*'],
      disallowedRoutes: ['/cars/view/:state/:city/:suburb/:slug/:dealerId/:stock/pre-approval'],
      siteGroup: 'mymoto',
    },
    {
      siteHandles: ['downerservice'],
      allowedRoutes: ['/car-servicing/book', '/', '/fonts/*'],
      disallowedRoutes: ['/sitemap'],
      siteGroup: 'servicing',
    },
    {
      siteHandles: [
        'carplace',
        'youngswa',
        'carco',
        'carcoTest',
        'midlandCity',
        'maddingtonCity',
        'usedCarsWa',
        'berwickCity',
        'vicParkCity',
      ],
      allowedRoutes: ['*'],
      disallowedRoutes: ['/service-specials*', '/complimentary-inspection*', '/deposit/*'],
      siteGroup: 'usedCarPlatform',
    },
    {
      siteHandles: ['waCarBuyingCentre', 'perthCarBuying', 'easyCarBuyers'],
      allowedRoutes: ['*'],
      disallowedRoutes: [
        '/cars*',
        '/car-servicing*',
        '/favourites*',
        '/service-specials*',
        '/complimentary-inspection*',
        '/deposit/*',
        '/locations*',
        '/sitemap',
      ],
      siteGroup: 'buyingCentre',
    },
    {
      siteHandles: ['mymotoFinance'],
      allowedRoutes: ['*'],
      disallowedRoutes: [
        '/cars*',
        '/car-servicing*',
        '/favourites*',
        '/service-specials*',
        '/complimentary-inspection*',
        '/deposit/*',
        '/locations*',
        '/sitemap',
      ],
      siteGroup: 'finance',
    },
  ];

  /**
   * Given locale get site config
   * @throws Error when there is no site config relevant to locale
   */
  public static getSiteConfig(locale: string): SiteGroupRouteConfig {
    const siteConfig = SiteGroupRoutes.routes.find((config) => config.siteHandles.includes(locale));

    if (!siteConfig) throw new Error(`Site config for locale ${locale} could not be found.`);
    return siteConfig;
  }
  /**
   * Given locale get site group it belongs to
   * @throws Error when there is no site config relevant to locale
   */
  public static getSiteGroup(locale: string) {
    return SiteGroupRoutes.getSiteConfig(locale).siteGroup;
  }

  /**
   * Given locale get allowed routes
   * @throws Error when there is no site config relevant to locale
   */
  public static getAllowedRoutes(locale: string) {
    return SiteGroupRoutes.getSiteConfig(locale).allowedRoutes;
  }

  /**
   * Given locale get disallowed routes
   * @throws Error when there is no site config relevant to locale
   */
  public static getDisallowedRoutes(locale: string) {
    return SiteGroupRoutes.getSiteConfig(locale).disallowedRoutes;
  }

  /**
   * Given locale and route, return whether that route is disabled for that locale or not
   * @throws Error when there is no site config relevant to locale
   */
  public static isRouteEnabled(locale: string, path: string) {
    return SiteGroupRoutes.getAllowedRoutes(locale).some((r) => new UrlPattern(r).match(path));
  }

  /**
   * Given locale and route, return whether that route is enabled for that locale or not
   * @throws Error when there is no site config relevant to locale
   */
  public static isRouteDisabled(locale: string, path: string) {
    return SiteGroupRoutes.getDisallowedRoutes(locale).some((r) => new UrlPattern(r).match(path));
  }
}

export type { SiteGroupRouteConfig };
