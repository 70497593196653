import React, { useEffect, useMemo } from 'react';
import { configure, runInAction } from 'mobx';
import { Provider, enableStaticRendering } from 'mobx-react';
import { setAppElement } from 'react-modal';
import { useEffectOnce } from 'react-use';
import { AppProps } from 'next/app';
import Head from 'next/head';

import Favicon from '@/components/Favicon';
import { useStores } from '@/hooks/useStores';
import CSRF from '@/lib/Csrf';
import { Font } from '@/lib/Font';
import { getReferrer, setReferrerCookie } from '@/lib/referrer';
import '@/styles/font.scss';
import '@/styles/main.scss';

enableStaticRendering(typeof window === 'undefined');
configure({ enforceActions: 'observed' });
setAppElement('#__next');

export default function Main({ Component, pageProps }: AppProps) {
  const stores = useStores(pageProps.mobxState);
  if (typeof window !== 'undefined') {
    stores.appState.init();
  }

  const font = useMemo(
    () => new Font(stores.globals.customization.font || 'Avenir Next W01'),
    [stores.globals.customization.font],
  );

  const isCarPlace = stores.globals.siteName === 'CarPlace';

  const carplaceBannerFont = useMemo(() => (isCarPlace ? new Font('MuseoModerno') : null), [isCarPlace]);

  useEffectOnce(() => {
    const setCSRF = async () => {
      if (!stores.appState.csrfToken) {
        const csrf = await CSRF.getCsrf();
        if (csrf) {
          runInAction(() => {
            stores.appState.csrfToken = csrf.slice();
          });
        }
      }
    };
    setCSRF();
  });

  // Set referrer if not set
  useEffect(() => {
    if (!stores.appState.referrer) {
      const ref = getReferrer();
      stores.appState.setReferrer(ref);
      setReferrerCookie(ref);
    }
  }, [stores.appState]);

  // Stop persisting stores on cleanup
  useEffect(() => {
    return () => {
      stores.appState.stopPersisting();
      stores.shortlistStore.stopPersisting();
    };
  }, [stores.appState, stores.shortlistStore]);

  return (
    <Provider {...stores}>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=yes, minimum-scale=1.0"
        />
        <Favicon favicon={stores.globals.general.favicon} />
        {font.getStyleLinkProps().map((f) => (
          <link {...f} key={f.key} />
        ))}
        {carplaceBannerFont && carplaceBannerFont.getStyleLinkProps().map((f) => <link {...f} key={f.key} />)}
        <style id="customStyles" key="customStyles" dangerouslySetInnerHTML={{ __html: font.getStyles() || '' }} />
      </Head>
      <Component {...pageProps} />
    </Provider>
  );
}
