export enum DisclaimerBlockType {
  REWARDS_DISCLAIMER_BLOCK_TYPE = 'rewardsContent_rewardDisclaimerItem_BlockType',
  QUALITY_ASSURANCE_DISCLAIMER_BLOCK_TYPE = 'qualityAssuranceContent_qualityAssuranceDisclaimerItem_BlockType',
  REDBOOK_DISCLAIMER_BLOCK_TYPE = 'redbookVehicleDataContent_redbookVehicleDataDisclaimerItem_BlockType',
}

export enum DisclaimerSlugs {
  WeeklyPerWeekDisclaimer = 'weekly-per-week-disclaimer',
  QualityAssuranceDisclaimer = 'quality-assurance-disclaimer',
  RewardsDisclaimer = 'rewards-disclaimer',
  EgcPriceDisclaimer = 'egc-price-disclaimer',
  DriveawayPriceDisclaimer = 'driveaway-price-disclaimer',
  RedbookDisclaimer = 'redbook-disclaimer',
}

export enum VizyDisclaimerTitle {
  REDBOOK_DISCLAIMER_TITLE = 'Redbook vehicle data disclaimer',
}

export const TERMS_AND_CONDITIONS_DEFAULT_ENTRY = 'termsAndConditions_default_Entry' as const;
export const DRIVE_TYPES = ['4WD', '4x4', 'AWD', 'FWD', 'RWD'] as const;
