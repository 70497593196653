import { CombinedError, ErrorLike, Operation } from 'urql';

export default class GraphQLError extends Error {
  combinedError: CombinedError;
  operation: Operation;
  graphQLErrors: Array<string | ErrorLike>;
  networkError: string | undefined;
  errorCause: unknown;

  constructor(combinedError: CombinedError, operation: Operation) {
    super();
    this.operation = operation;
    this.combinedError = combinedError;
    const topError = combinedError?.graphQLErrors?.[0]?.message || combinedError?.networkError?.message || 'unknown';
    this.name = `GraphQLError: ${topError}`;
    this.message = `GraphQLError: ${topError}`;
    this.graphQLErrors = combinedError.graphQLErrors;
    this.networkError = combinedError.networkError ? JSON.stringify(combinedError.networkError) : undefined;
    this.errorCause = combinedError.cause ? JSON.stringify(combinedError.cause) : undefined;
  }
}
