import { LinkHTMLAttributes } from 'react';

export type FontFamilies =
  | 'Avenir Next W01'
  | 'Nunito'
  | 'Lexend'
  | 'century-gothic'
  | 'lato'
  | 'Noto+Sans'
  | 'Poppins'
  | 'Rubik'
  | 'Montserrat'
  | 'Neue Kabel'
  | 'MuseoModerno'
  | 'Lexend Deca';

interface FontWeights {
  headingWeight: string;
  bodyWeight: string;
  demiWeight: string;
  heavyWeight: string;
  filterPillWeight: string;
}

type FontWeightMap = Record<FontFamilies, Record<keyof FontWeights, string>>;

const FONT_WEIGHT_MAP: FontWeightMap = {
  'Avenir Next W01': {
    headingWeight: '600',
    bodyWeight: 'normal',
    demiWeight: '600',
    heavyWeight: '900',
    filterPillWeight: '600',
  },
  Nunito: {
    headingWeight: '800',
    bodyWeight: '500',
    demiWeight: '600',
    heavyWeight: '900',
    filterPillWeight: '800',
  },
  Lexend: {
    headingWeight: '500',
    bodyWeight: '300',
    demiWeight: '500',
    heavyWeight: '900',
    filterPillWeight: '500',
  },
  'century-gothic': {
    headingWeight: '700',
    bodyWeight: '400',
    demiWeight: '700',
    heavyWeight: '700',
    filterPillWeight: '700',
  },
  lato: {
    headingWeight: '700',
    bodyWeight: 'normal',
    demiWeight: '600',
    heavyWeight: 'bold',
    filterPillWeight: '700',
  },
  'Noto+Sans': {
    headingWeight: '600',
    bodyWeight: 'normal',
    demiWeight: '500',
    heavyWeight: '600',
    filterPillWeight: '600',
  },
  Poppins: {
    headingWeight: '600',
    bodyWeight: 'normal',
    demiWeight: '600',
    heavyWeight: '600',
    filterPillWeight: '600',
  },
  Rubik: {
    headingWeight: '600',
    bodyWeight: 'normal',
    demiWeight: '600',
    heavyWeight: '600',
    filterPillWeight: '600',
  },
  Montserrat: {
    headingWeight: '700',
    bodyWeight: 'normal',
    demiWeight: '500',
    heavyWeight: '800',
    filterPillWeight: '500',
  },
  'Neue Kabel': {
    headingWeight: '700',
    bodyWeight: 'normal',
    demiWeight: '500',
    heavyWeight: '700',
    filterPillWeight: '500',
  },
  MuseoModerno: {
    headingWeight: '700',
    bodyWeight: 'normal',
    demiWeight: '500',
    heavyWeight: '700',
    filterPillWeight: '500',
  },
  'Lexend Deca': {
    headingWeight: '700',
    bodyWeight: 'normal',
    demiWeight: '500',
    heavyWeight: '700',
    filterPillWeight: '500',
  },
};

/**
 * Any fonts that aren't provided through google (https://fonts.google.com/) should be defined here.
 * Be sure to include `http` for web links
 */
const NON_GOOGLE_FONTS: Partial<Record<FontFamilies, string[]>> = {
  'Avenir Next W01': [
    '/fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2',
    '/fonts/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2',
    '/fonts/14c73713-e4df-4dba-933b-057feeac8dd1.woff2',
  ],
  'century-gothic': ['https://use.typekit.net/dnc1lob.css'],
  lato: ['/fonts/lato-regular.woff2', '/fonts/lato-bold.woff2', '/fonts/lato-italic.woff2'],
  'Neue Kabel': ['/fonts/NeueKabel-Regular.woff2', '/fonts/NeueKabel-Bold.woff2', '/fonts/NeueKabel-Italic.woff2'],
};

export class Font implements FontWeights {
  name: FontFamilies;
  headingWeight: string;
  bodyWeight: string;
  demiWeight: string;
  heavyWeight: string;
  filterPillWeight: string;

  constructor(font: FontFamilies) {
    const weights = FONT_WEIGHT_MAP[font];
    this.name = font;
    this.headingWeight = weights.headingWeight;
    this.demiWeight = weights.demiWeight;
    this.bodyWeight = weights.bodyWeight;
    this.heavyWeight = weights.heavyWeight;
    this.filterPillWeight = weights.filterPillWeight;
  }

  getStyleLinkProps(): Array<LinkHTMLAttributes<HTMLLinkElement> & { key: string }> {
    const linkAttr: Array<LinkHTMLAttributes<HTMLLinkElement> & { key: string }> = [];
    if (Object.keys(NON_GOOGLE_FONTS).includes(this.name)) {
      NON_GOOGLE_FONTS[this.name]?.forEach((f) =>
        f.startsWith('http')
          ? linkAttr.push({ key: f, rel: 'stylesheet', href: f })
          : linkAttr.push({
              key: f,
              rel: 'preload',
              href: f,
              as: 'font',
              type: 'font/woff2',
              crossOrigin: 'anonymous',
            }),
      );
    } else {
      linkAttr.push({
        key: 'googleFont',
        href: `https://fonts.googleapis.com/css?family=${this.name}:300,400,400i,500,600,700,800&display=swap`,
        rel: 'stylesheet',
        id: 'googleFont',
      });
    }

    return linkAttr;
  }

  getStyles() {
    const fontName = this.name?.replace(/\+/g, ' ');
    return `
    *, button, input, optgroup, select, textarea { font-family: "${fontName}", Helvetica, Arial, sans-serif !important; }
  :root {
        --font-name: "${fontName}";
        --font-heading-weight: ${this.headingWeight} !important;
        --font-body-weight: ${this.bodyWeight} !important;
        --font-demi-weight: ${this.demiWeight} !important;
        --font-heavy-weight: ${this.heavyWeight} !important;
        --font-filter-pill-weight: ${this.filterPillWeight} !important;
        --font-letter-spacing: ${fontName === 'Lexend' ? '0' : '1'} !important;
      }
      `;
  }
}
