import { CombinedError } from 'urql';

export function extractErrorMessage(error: CombinedError | undefined): Nullable<string | Record<string, unknown>> {
  let message = null;

  if (error) {
    try {
      const buffer: Buffer | undefined = error.response?._raw;
      if (buffer) {
        message = buffer.toString(); // First try a plain string
        message = JSON.parse(buffer.toString()); // Then try to parse it as JSON
      }
    } catch (e) {}
  }

  return message;
}

export function extractItems<T>(items: Array<Record<string, never> | T | undefined | null> | undefined | null) {
  // NOTE: Typescript bug, waiting on 5.0 typescript update
  // see: https://github.com/microsoft/TypeScript/pull/51502
  // eslint-disable-next-line
  // @ts-ignore
  return items?.filter((item): item is T => !!item && '__typename' in item) || null;
}

export function extractFragment<T>(entry: Record<string, never> | T | undefined | null) {
  // NOTE: Typescript bug, waiting on 5.0 typescript update
  // see: https://github.com/microsoft/TypeScript/pull/51502
  // eslint-disable-next-line
  // @ts-ignore
  return !!entry && '__typename' in entry ? (entry as T) : null;
}
