import Cookies from 'js-cookie';

import Log from './Log';

const COOKIE_NAME = 'referral';

type referralType = 'Param Referral' | 'Social Referral' | 'Referral' | 'Direct';

const REFERRAL_PARAMS = <const>['gclid', 'fbclid'];
type referralParam = (typeof REFERRAL_PARAMS)[number];

export interface Referral {
  type: referralType;
  param?: referralParam;
  value?: string;
}

const SOCIAL_REFERRAL_DOMAINS = <const>['facebook', 'fb', 'twitter', 't', 'instagram'];

/**
 * Set referrer session cookie
 * @param referral
 */
export const setReferrerCookie = (ref: Referral) => {
  debugLog('setReferrerCookie', `setting "${COOKIE_NAME}" cookie`, { ref });
  Cookies.set(COOKIE_NAME, JSON.stringify(ref));
};

/**
 * Attempt to get refferer from document. Function is client-side only.
 *
 * Uses the following priority:
 * 1. Cookie
 * 2. Search Params
 * 3. HTTP referer (socials refferal)
 * 4. HTTP referer (any)
 * 5. Organic (no referer)
 */
export const getReferrer = (): Referral => {
  const ref: Referral = {
    type: 'Direct',
  };

  if (typeof window === 'undefined') {
    Log.error('referrer.ts; getReferrer(): should only be called on client-side', {});
    return ref;
  }

  return getRefByCookie() ?? getRefByParams() ?? getRefByReferer() ?? ref;
};

const getRefByCookie = (): Nullable<Referral> => {
  const ref = Cookies.get(COOKIE_NAME);
  if (ref) {
    debugLog('getRefByCookie', 'setting ref from cookie', { cookie: ref });
    return JSON.parse(ref);
  }
  return null;
};

const getRefByParams = (): Nullable<Referral> => {
  const searchParams = new URLSearchParams(window.location.search);

  // If search params includes one of
  for (const [_key, value] of searchParams) {
    // validate key type
    const key = REFERRAL_PARAMS.find((f) => f === _key);
    if (key && REFERRAL_PARAMS.some((s) => s === key)) {
      const ref: Referral = {
        type: 'Param Referral',
        param: key,
        value: value,
      };
      debugLog('getRefByParams', 'setting ref from params', ref);

      return ref;
    }
  }

  return null;
};

const getRefByReferer = (): Nullable<Referral> => {
  if (!!document.referrer) {
    // Check for social referrer
    if (!!document.referrer.match(new RegExp(`^.*(${SOCIAL_REFERRAL_DOMAINS.join('|')})\\.\\w+`, 'g'))) {
      debugLog('getRefByReferer', 'setting social referral', { referrer: document.referrer });
      return {
        type: 'Social Referral',
        value: document.referrer,
      };
    }

    // Otherwise return generic referral if not same-site referral
    try {
      if (window.location.host !== new URL(document.referrer).host) {
        debugLog('getRefByReferer', 'setting Referral referral', { referrer: document.referrer });
        return {
          type: 'Referral',
          value: document.referrer,
        };
      }
    } catch (e) {
      // Should only error on invalid URL construct; which shouldn't happen due to conditional guard
      Log.error('referrer.ts; getRefByReferer(): Failed to construct URL', {
        error: e,
        locationHref: location.href,
        documentReferrer: document.referrer,
      });

      return null;
    }
  }

  return null;
};

const debugLog = (fn: string, message: string, extras?: Record<string, any>) => {
  console.debug(`referrer.ts; ${fn}(): ${message}`, extras ? { ...extras } : '');
};

// functions/vars exported for tests only
export const _referrerExportsForTest = {
  COOKIE_NAME,
  getRefByCookie,
  getRefByParams,
  getRefByReferer,
  SOCIAL_REFERRAL_DOMAINS,
};
