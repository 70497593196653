import { action, computed, flow, makeAutoObservable } from 'mobx';
import { isHydrated, makePersistable, stopPersisting } from 'mobx-persist-store';
import { Id } from 'react-toastify';
import { minBy } from 'lodash';

import sortOptions from '@/components/SearchDirectory/SortOptions';
import Log from '@/lib/Log';
import { Referral } from '@/lib/referrer';
import { localStorage, localStorageSupported, sessionStorage } from '@/lib/Storage';
import { Filter } from '@/models/index';

export default class AppState {
  static filterKey: string = Object.freeze('filters');
  static weightKey: string = Object.freeze('weight');
  static sortKey: string = Object.freeze('sort');
  static totalKey: string = Object.freeze('total');
  static filterReferrerKey: string = Object.freeze('filterReferrer');
  static resultIndexKey: string = Object.freeze('resultIndex');
  static resultsLinkKey: string = Object.freeze('resultsLink');
  static fixedListKey: string = Object.freeze('fixedList');
  static dealershipReferrer: string = Object.freeze('dealershipReferrer');
  static referrerKey: string = Object.freeze('referrer');

  // contextual strings
  gulliver = typeof window !== 'undefined' && window.location.hostname.includes('gulliveroutlet.');
  fullName = 'DVG Automotive';
  name = 'DVG';
  dealershipText = 'Dealership';
  smartclub = typeof window !== 'undefined' && window.location.hostname.includes('referral.');

  serviceCentre = typeof window !== 'undefined' && window.location.pathname.includes('service-centre');
  stockPage = typeof window !== 'undefined' && window.location.pathname.includes('/stock');

  hydrated = false;

  cachedFilters: Array<Filter> = [];
  cachedWeights: Array<Filter> = [];
  cachedFeatureFilters: Array<string> = [];
  cachedFiltersReferrer?: string = '/cars';
  resultIndex?: number;
  resultsLink = '/cars';
  dealershipReferrer = '';
  pulseButton = false;

  auction: boolean = typeof window !== 'undefined' && window.location.pathname.includes('auction');
  fixedList: string[] = [];

  viewedCars: Record<string, string> = {}; // stockNo: dateViewed
  viewedCarsList: StockItem[] = []; // will be populated when loadViewedVehicles is called
  viewedCarsLimit = 25;
  viewedCarsLoaded = false;

  csrfToken = '';

  // Persisted data
  schemaVersion?: string;
  cachedSortMethod: SortMethod = sortOptions[0].sortMethod;

  tradeInEstimated = false;
  tradeInEstimate = 0;
  tradeInVehicleName = '';
  tradeInVehicleInfo = '';
  showTradeInPrice = false;
  postcode?: string;

  tradeInCreatedTime?: string;
  tradeInVehicle: TradeInVehicle = {
    make: '',
    model: '',
    year: '',
    variant: '',
    transmission: '',
    odometer: '',
    condition: '',
    min: 0,
    max: 0,
    images: [],
    underFinance: null,
    financeAmount: null,
    plate: '',
    vin: '',
  };

  interestRate?: number;
  bannerColor?: string;
  bannerHeading?: string;
  bannerLogo?: string;

  userDarkMode?: boolean = undefined;

  useCloseIconModal = false;

  referrer: Nullable<Referral> = null;

  // set height to header if sticky header is active
  stickyHeaders: {
    header: Nullable<number>;
    filters: Nullable<number>;
  } = {
    header: null,
    filters: null,
  };

  activeToastId: Nullable<Id> = null;

  mobileMenuOpen = false;

  // Change this value to clear out all persisted data.
  currentSchemaVersion = '6';

  // Used for form prefill
  customerInfo = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };

  autogateResponse: Nullable<AutoGate.ResponseTypes> = null;

  constructor(initialProps?: Partial<AppState>) {
    Object.assign(this, initialProps);

    /* istanbul ignore else */
    if (!this.schemaVersion) {
      this.schemaVersion = this.currentSchemaVersion;
    }

    this.cachedFiltersReferrer = sessionStorage.getItem(AppState.filterReferrerKey) || '/cars';
    this.auction = !!this.cachedFiltersReferrer?.includes('auction');
    this.resultsLink = sessionStorage.getItem(AppState.resultsLinkKey) || '/cars';

    const filterKeyValue = sessionStorage.getItem(AppState.filterKey);
    if (filterKeyValue) {
      const cachedFilters: Filter[] = JSON.parse(filterKeyValue);
      this.cachedFilters = cachedFilters;
      this.cachedFiltersReferrer = sessionStorage.getItem(AppState.filterReferrerKey) || '/cars';
      this.cachedFeatureFilters = cachedFilters
        .filter((f) => f.type === 'features')
        .map((filter) => filter.value as string);
    }

    if (!!this.cachedWeights?.length) {
      this.cachedWeights = this.cachedWeights.map((w) => new Filter(w as any));
    }

    const storedRef = sessionStorage.getItem(AppState.referrerKey);
    if (storedRef) this.referrer = JSON.parse(storedRef);

    makeAutoObservable(this, {
      viewedCount: computed,
      specialBanner: computed,
      viewedStockNoArr: computed,
      darkModeActive: computed,
      toggleMobileMenu: action,
    });
    if (typeof window !== 'undefined') {
      makePersistable(this, {
        name: `appState-v${this.currentSchemaVersion}`,
        properties: [
          'cachedFeatureFilters',
          'viewedCars',
          'schemaVersion',
          'cachedSortMethod',
          'tradeInEstimated',
          'tradeInEstimate',
          'tradeInVehicleName',
          'tradeInVehicleInfo',
          'showTradeInPrice',
          'postcode',
          'interestRate',
          'bannerColor',
          'bannerHeading',
          'bannerLogo',
          'userDarkMode',
          'tradeInVehicle',
          'tradeInCreatedTime',
          'customerInfo',
          'autogateResponse',
        ],
        storage: localStorageSupported ? localStorage : undefined,
      });
    }
  }

  init = () => {
    this.hydrated = true;

    if (this.tradeInCreatedTime) {
      const expiryDate = new Date(this.tradeInCreatedTime);
      // If the logged date of trade in vehicle is expired, create a new date
      if (expiryDate <= new Date()) {
        const newDate = new Date();
        newDate.setHours(newDate.getHours() + 24);
        this.tradeInCreatedTime = newDate.toString();
        this.clearTradeIn();
      }
    } else {
      // Always create date on init. If the 24hrs has passed then we clear
      const newDate = new Date();
      newDate.setHours(newDate.getHours() + 24);
      this.tradeInCreatedTime = newDate.toString();
    }
  };

  stopPersisting = () => {
    stopPersisting(this);
  };

  get isHydrated() {
    return isHydrated(this);
  }

  updateFilters = (filters: Array<Filter>) => {
    sessionStorage.setItem(AppState.filterKey, JSON.stringify(filters));
    this.cachedFilters = filters;
    this.updateFilterReferrer(document.location.pathname);
  };

  updateWeights = (weights: Array<Filter>) => {
    sessionStorage.setItem(AppState.weightKey, JSON.stringify(weights));
    this.cachedWeights = weights;
  };

  setDealershipReferrer = (dealerId: string) => {
    /* istanbul ignore next */
    sessionStorage.setItem(AppState.dealershipReferrer, JSON.stringify(dealerId));
  };

  updateSortMethod = (sortMethod: SortMethod) => {
    /* istanbul ignore next */
    this.cachedSortMethod = sortMethod;
  };

  setResultIndex = (index: number) => {
    /* istanbul ignore next */
    sessionStorage.setItem(AppState.resultIndexKey, index.toString());
    this.resultIndex = index;
  };

  updateResultsLink = (link: string) => {
    sessionStorage.setItem(AppState.resultsLinkKey, link);
    this.resultsLink = link;
  };

  updateFilterReferrer = (link: string) => {
    sessionStorage.setItem(AppState.filterReferrerKey, link);
    this.cachedFiltersReferrer = link;
  };

  resetResultsLink = () => this.updateResultsLink('/cars');

  setPostcode = (postcode: string) => {
    this.postcode = postcode;
  };

  updateViewed = (vehicle: StockItem) => {
    if (!this.hasViewed(vehicle.stockNo)) {
      // if viewedCars is at the limit then remove the oldest viewed car
      const stockNos = Object.keys(this.viewedCars);
      if (stockNos.length >= this.viewedCarsLimit) {
        const oldestStockNo = minBy(stockNos, (stockNo) => {
          return new Date(this.viewedCars[stockNo]);
        });
        if (!!oldestStockNo) {
          delete this.viewedCars[oldestStockNo];
        }
      }
    }
    this.viewedCars[vehicle.stockNo] = new Date().toString();
  };

  hasViewed = (stockNo: string) => {
    return !!this.viewedCars[stockNo];
  };

  setReferrer = (value: Referral | null) => {
    sessionStorage.setItem(AppState.referrerKey, JSON.stringify(value));
    this.referrer = value;
  };

  get viewedCount() {
    return this.viewedCars.size;
  }

  get specialBanner() {
    return this.bannerColor && this.bannerHeading
      ? {
          color: this.bannerColor,
          heading: this.bannerHeading,
          logo: this.bannerLogo,
        }
      : false;
  }

  get viewedStockNoArr() {
    const viewedCarsArr: string[] = [];
    const today = new Date();

    Object.entries(this.viewedCars).forEach(([stockNo, dateString]) => {
      const viewedDate = new Date(dateString);
      const differenceInMilliseconds = today.getTime() - viewedDate.getTime();
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

      if (differenceInDays > 14) {
        return;
      }
      viewedCarsArr.push(`stockNo:${stockNo}`);
    });
    return viewedCarsArr;
  }

  get tradeInPriceWithComma() {
    if (this.tradeInEstimate === 0) {
      return '< $500';
    }

    const priceComma = this.tradeInEstimate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `$${priceComma}`;
  }

  get tradeInString() {
    if (this.tradeInPriceWithComma) {
      return `Trade-In:\nVehicle: ${this.tradeInVehicleName}\nAdditional information: ${this.tradeInVehicleInfo}\nEstimate: ${this.tradeInPriceWithComma}`;
    }
    return '';
  }

  loadViewedVehicles = flow(function* f(this: AppState) {
    if (this.viewedStockNoArr.length === 0) {
      this.viewedCarsLoaded = true;
      return;
    }

    try {
      const response = yield fetch(
        `/api/stock.json?limit=${this.viewedCarsLimit}&sort=price.desc&filter=${this.viewedStockNoArr.join(
          ',',
        )}&offset=0`,
      );
      const json = yield response.json();

      if (json?.stock?.length) {
        const stock: Array<StockItem> = json.stock;
        this.viewedCarsList = stock;

        // if the returned stock is not the same as existing viewedCars -> filter out the cars not returned in the above api call
        // this case will occur when cars with view dates older than 2 week are filtered out
        // or when a car is no longer available for purchase
        if (stock.length !== Object.keys(this.viewedCars).length) {
          const newViewedCars: Record<string, string> = {};
          stock.forEach((stockItem) => {
            const viewDate = this.viewedCars[stockItem.stockNo];
            const stockNo = stockItem.stockNo;
            newViewedCars[stockNo] = viewDate;
          });
          this.viewedCars = newViewedCars;
        }
      }
      this.viewedCarsLoaded = true;
      return true;
    } catch (e) {
      Log.exception(e, {});
      return false;
    }
  });

  setShowTradeInPrice = (value: boolean) => (this.showTradeInPrice = value);

  tradeInClear = () => {
    this.showTradeInPrice = false;
    this.tradeInEstimated = false;
    this.tradeInEstimate = 0;
  };

  get darkModeActive() {
    if (typeof this.userDarkMode !== 'undefined') {
      return this.userDarkMode;
    }

    if (this.gulliver) return false;

    const currentHour = new Date().getHours();
    return currentHour < 7 || currentHour > 19;
  }

  toggleDarkMode() {
    const currentDarkModeActive = this.darkModeActive;
    this.userDarkMode = !currentDarkModeActive;
  }

  pulseButtonTimeout = (timeout = 3000) => {
    this.togglePulse();
    setTimeout(() => {
      this.togglePulse();
    }, timeout);
  };

  togglePulse = () => {
    this.pulseButton = !this.pulseButton;
  };

  clearTradeIn = () => {
    this.tradeInEstimate = 0;
    this.tradeInEstimated = false;
    this.tradeInVehicle = {
      make: '',
      model: '',
      year: '',
      variant: '',
      transmission: '',
      odometer: '',
      condition: '',
      min: 0,
      max: 0,
      images: [],
      underFinance: null,
      financeAmount: null,
    };
  };

  updateActiveToastId = (newId: Id) => {
    this.activeToastId = newId;
  };

  toggleMobileMenu = () => (this.mobileMenuOpen = !this.mobileMenuOpen);

  updateCustomerInfo = (customerInfoObj?: AppState['customerInfo']) => {
    this.customerInfo = customerInfoObj || {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    };
  };

  setAutoGateResponse = (newAutoGateResponse: AutoGate.ResponseTypes) => {
    this.autogateResponse = newAutoGateResponse;
  };
}
