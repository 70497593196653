import Log from '@/lib/Log';

import SendRequest from './SendRequest';

interface SessionResponse {
  isGuest: boolean;
  timeout: number;
  csrfTokenValue: string;
}

export default class CSRF {
  static getCsrf = async () => {
    try {
      const session = await SendRequest<null, SessionResponse>({
        path: '/api/users/session-info',
      });

      if (session?.csrfTokenValue) {
        return session.csrfTokenValue;
      }
      return '';
    } catch (error) {
      Log.exception(error, {});
      return '';
    }
  };
}
