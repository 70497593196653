type environmentTypes = 'development' | 'staging' | 'production' | 'test' | 'unknown';

interface PublicEnvironmentVariables {
  APP_VERSION: string;
  ENV: environmentTypes;
  MOCK_GRAPHQL_SERVER: boolean;
  MOCK_REST_SERVER: boolean;
  NGINX_HOST: string;
  SENTRY_DSN: string;
  SENTRY_ENABLED: boolean;
  STELLATE_DISABLED: boolean;
  MOCK_GRAPHQL: boolean;
  NEXT_PUBLIC_ENV: environmentTypes | (string & NonNullable<unknown>); // HACK: string & {} preserves string literal type hints
  MAPBOX_TOKEN: 'nokey' | (string & NonNullable<unknown>);
  GOOGLE_API_KEY: 'nokey' | (string & NonNullable<unknown>);
  S3_BUCKET: 'mymoto-assets' | 'mymoto-staging' | (string & NonNullable<unknown>);
  PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY__DEFAULT: string;
  PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY__USED_CARS: string;
  PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY__BUYING: string;
  IMPEL_ENABLE_TRACKING_SCRIPT: boolean;
  MYMOTO_FACTS_ENABLED: boolean;
}

export default class PublicEnvironment {
  static #instance: PublicEnvironment;

  env: PublicEnvironmentVariables = {
    APP_VERSION: 'latest',
    ENV: 'unknown',
    MOCK_GRAPHQL_SERVER: false,
    MOCK_REST_SERVER: false,
    NGINX_HOST: 'localhost',
    SENTRY_DSN: '',
    SENTRY_ENABLED: false,
    STELLATE_DISABLED: true,
    MOCK_GRAPHQL: false,
    NEXT_PUBLIC_ENV: 'unknown',
    MAPBOX_TOKEN: 'nokey',
    GOOGLE_API_KEY: 'nokey',
    S3_BUCKET: 'mymoto-staging',
    PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY__DEFAULT: 'LOL',
    PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY__USED_CARS: 'LOL',
    PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY__BUYING: 'LOL',
    IMPEL_ENABLE_TRACKING_SCRIPT: false,
    MYMOTO_FACTS_ENABLED: false,
  };

  constructor(initialProps?: Partial<PublicEnvironment>) {
    if (typeof window !== 'undefined' && window.__NEXT_DATA__?.props.pageProps.mobxState.publicEnvironment) {
      // On Client side, we can use __NEXT_DATA__ immediately to initialise this singleton
      Object.assign(this, JSON.parse(window.__NEXT_DATA__.props.pageProps.mobxState.publicEnvironment));
    } else if (initialProps) {
      Object.assign(this, initialProps);
    } else {
      this.env.MOCK_GRAPHQL_SERVER = process.env.MOCK_GRAPHQL_SERVER === 'true';
      this.env.MOCK_REST_SERVER = process.env.MOCK_REST_SERVER === 'true';
      this.env.NGINX_HOST = process.env.NGINX_HOST || 'localhost';
      this.env.ENV = this.#getEnv();
      this.env.APP_VERSION = process.env.APP_VERSION || 'latest';
      this.env.SENTRY_DSN = process.env.SENTRY_DSN || '';
      this.env.SENTRY_ENABLED = process.env.SENTRY_ENABLED === 'true';
      this.env.STELLATE_DISABLED = process.env.STELLATE_DISABLED === 'true';
      this.env.MOCK_GRAPHQL = process.env.MOCK_GRAPHQL === 'true';
      this.env.NEXT_PUBLIC_ENV = process.env.ENV || process.env.BITBUCKET_BRANCH || process.env.BRANCH || 'unknown';
      this.env.MAPBOX_TOKEN = process.env.MAPBOX_TOKEN || 'nokey';
      this.env.GOOGLE_API_KEY = process.env.GOOGLE_MAPS_KEY || process.env.GOOGLE_API_KEY || 'nokey';
      this.env.S3_BUCKET =
        process.env.S3_BUCKET ||
        (process.env.BITBUCKET_BRANCH === 'master' || process.env.BRANCH === 'master'
          ? 'mymoto-assets'
          : 'mymoto-staging');
      this.env.PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY__DEFAULT =
        process.env.PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY__DEFAULT ?? '';
      this.env.PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY__USED_CARS =
        process.env.PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY__USED_CARS ?? '';
      this.env.PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY__BUYING =
        process.env.PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY__BUYING ?? '';
      this.env.IMPEL_ENABLE_TRACKING_SCRIPT = process.env.IMPEL_ENABLE_TRACKING_SCRIPT === 'true';
      this.env.MYMOTO_FACTS_ENABLED = process.env.MYMOTO_FACTS_ENABLED === 'true';
    }
  }

  static getInstance(initialProps?: Partial<PublicEnvironment>) {
    if (!PublicEnvironment.#instance) {
      PublicEnvironment.#instance = new PublicEnvironment(initialProps);
    }
    return PublicEnvironment.#instance;
  }

  static get<T extends keyof PublicEnvironmentVariables>(key: T): PublicEnvironmentVariables[T] {
    const instance = this.getInstance();
    return instance.env[key];
  }

  #getEnv() {
    if (process.env.NODE_ENV === 'test') {
      return 'test';
    }
    const maybeEnv = process.env.ENV;
    if (!maybeEnv || !['development', 'staging', 'production'].includes(maybeEnv)) {
      return 'unknown' as const;
    }
    return maybeEnv as PublicEnvironmentVariables['ENV'];
  }
}
