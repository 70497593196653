import PublicEnvironment from '@/stores/PublicEnvironment';

interface SendRequestParams<T> {
  path: string;
  headers?: Record<string, any>;
  body?: T;
  formBody?: BodyInit;
  method?: 'POST' | 'GET';
  searchParams?: URLSearchParams;
}

/**
 * Simple fetch wrapper for sending request isomorphically.
 * @param params
 * @throws Error SendRequest failed
 * @returns json data
 */

export default async function SendRequest<T, R extends Record<string, any>>({
  path,
  headers,
  body,
  formBody,
  method = 'GET',
  searchParams,
}: SendRequestParams<T>): Promise<R | null> {
  const [protocol, server] = getProtocol();
  const search = searchParams ? '?' + searchParams.toString() : '';

  const _headers = new Headers({
    Accept: 'application/json',
    ...headers,
  });
  if (body) _headers.set('Content-Type', 'application/json');

  const response = await fetch(`${protocol}//${server}${path}` + search, {
    method,
    body: body ? JSON.stringify(body) : formBody,
    headers: _headers,
    credentials: 'same-origin',
  });
  const json: R = await response.json();
  return json;
}

export function getProtocol() {
  const server = PublicEnvironment.get('MOCK_REST_SERVER')
    ? `${process.env.HOSTNAME || '0.0.0.0'}:3001`
    : typeof window !== 'undefined'
    ? window.location.host
    : PublicEnvironment.get('NGINX_HOST') || 'localhost';
  const protocol = (typeof window !== 'undefined' && window.location.protocol) || 'http:';
  return [protocol, server];
}
