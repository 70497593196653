import { Client } from 'urql';

import Log from '@/lib/Log';
import Filter from '@/models/Filter';
import { GraphQLError } from 'graphql/error/GraphQLError';

import { isNetworkError } from '../isNetworkError';
import filtersQuery from './filters.graphql';
import makeFiltersQuery from './makeFilters.graphql';
import stockQuery from './stock.graphql';

export const loadFunction = async (urqlClient: Client, params: StockRequestParams): Promise<StockListFragment> => {
  const { data, error } = await urqlClient
    .query<StockQuery, StockQueryVariables>(stockQuery, {
      limit: Number(params.limit),
      offset: params.offset,
      similar: params.similar,
      filters: Filter.toQueryString(params.filter),
      weight: Filter.toQueryString(params.weight),
      sort: params.sort,
      sortByDealerIds: params.sortByDealerIds,
      pushedDownHashtag: params.pushedDownHashtag,
    })
    .toPromise();

  if (error) {
    if (isNetworkError(error)) {
      throw error;
    }
    Log.error('loadFunction: Failed to load stock', { error, params });
    throw new GraphQLError('loadFunction: Failed to load stock');
  }

  if (!data || !data.stockList) {
    Log.error('loadFunction: Returned undefined', { error, params });
    throw new Error('loadFunction: Returned undefined');
  }

  return data.stockList;
};

export const loadFiltersFunction = async (urqlClient: Client, filters: Filter[]): Promise<StockFiltersFragment> => {
  const { data, error } = await urqlClient
    .query<FiltersQuery>(filtersQuery, {
      filters: Filter.toQueryString(filters),
    })
    .toPromise();

  if (error) {
    if (isNetworkError(error)) {
      throw error;
    }
    Log.error('loadFiltersFunction: Failed to load filters', { error, filters });
    throw new GraphQLError('loadFiltersFunction: Failed to load filters');
  }

  if (!data || !data.stockFilters) {
    Log.error('loadFiltersFunction: Returned undefined', { error, filters });
    throw new Error('loadFiltersFunction: Returned undefined');
  }

  return data.stockFilters;
};

export const loadMakeFiltersFunction = async (
  urqlClient: Client,
  makeFilter: Maybe<Filter>,
): Promise<MakeSuggestFilterFragment> => {
  const { data, error } = await urqlClient
    .query<MakeFiltersQuery>(makeFiltersQuery, {
      makeFilter: makeFilter ? Filter.toQueryString([makeFilter]) : '',
    })
    .toPromise();

  if (error) {
    if (isNetworkError(error)) {
      throw error;
    }
    Log.error('loadMakeFiltersFunction: Failed to load filters', { error, makeFilter });
    throw new GraphQLError('loadMakeFiltersFunction: Failed to load filters');
  }

  if (!data || !data.makeSuggestFilters) {
    Log.error('loadMakeFiltersFunction: Returned undefined', { error, makeFilter });
    throw new Error('loadMakeFiltersFunction: Returned undefined');
  }

  return data.makeSuggestFilters;
};
