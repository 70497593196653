import { extractItems } from './GraphQLHelper';

export const getDepositVariants = (dealership: MainDealershipFragment): VehicleDeposit[] | null => {
  const deposit = extractItems<VehicleDepositFragment>(dealership.singleVehicleDeposit)?.[0];
  if (deposit && deposit.slug) {
    return (
      deposit.variants
        ?.filter((f): f is VehicleDepositVariantFragment => !!(f && '__typename' in f))
        .map((f) => ({
          slug: deposit.slug!,
          sku: f.sku!,
          price: f.price!,
        })) ?? null
    );
  }
  return null;
};

export const getMinDeposit = (dealership: MainDealershipFragment) => {
  const depositVariants = getDepositVariants(dealership)?.filter((f) => !!f?.price);
  if (depositVariants?.length) {
    return depositVariants.reduce((p, c) => (p!.price! < c!.price! ? p : c));
  }
  return null;
};

export const getMinDepositPrice = (dealership: MainDealershipFragment) => {
  return getMinDeposit(dealership)?.price ?? null;
};

export const getDealerIdFromInventorySource = (
  inventorySource: Maybe<InventorySourceAutogateFragment | InventorySourceCoxAutomotiveFragment>[],
) => {
  if (inventorySource[0]) {
    return inventorySource[0].__typename === 'inventorySource_coxAutomotive_BlockType'
      ? inventorySource[0].dealerId
      : inventorySource[0].__typename === 'inventorySource_autogate_BlockType'
      ? inventorySource[0].guidCode
      : null;
  }

  return null;
};
