var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ALgr_hUpWHNCg-HwnHn_H"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { ExtraErrorData } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

import { isNetworkError } from './lib/isNetworkError';
import PublicEnvironment from './stores/PublicEnvironment';

if (PublicEnvironment.get('SENTRY_ENABLED')) {
  Sentry.init({
    environment: PublicEnvironment.get('ENV'),
    dsn: PublicEnvironment.get('SENTRY_DSN'),
    release: PublicEnvironment.get('APP_VERSION'),
    // Current aim is 25k transactions/month. See: https://dvg.sentry.io/stats/?dataCategory=transactions&project=5811624
    tracesSampleRate: 0.03,
    ignoreErrors: [
      /Blocked a frame with origin/,
      /LCM/,
      /Script error/,
      /Failed to register a ServiceWorker/,
      /sw.js/,
      /NetworkError/,
      'document.getElementsByClassName.ToString is not a function',
      /Script load failed/,
      /International roaming is currently off/,
      /NotAllowedError/,
      '<unknown>',
      'undefined',
      "ReferenceError: Can't find variable: DC",
      'ReferenceError: DC is not defined',
      "ReferenceError: Can't find variable: YAHOO",
      'UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:*',
      // External plugin errors below
      `Unexpected identifier 'UA'. Expected ')' to end an argument list.`,
      'missing ) after argument list.',
      'Invalid value for ESTIMATE_MODIFIER - must be between 0 and 2',
      /Cannot read properties of null (reading 'src')/,
      /Failed to fetch/,
      /Load failed$/,
      /ResizeObserver/,
      `Can't find variable: ResizeObserver`,
      'ImpelAna',
      'SecurityError',
      'Unexpected token',
      `Failed to execute 'insertBefore'`,
      'The string did not match the expected pattern.',
      'The object can not be found here.',
      'Unexpected identifier',
      `Cannot read properties of null (reading 'removeChild')`,
      `null is not an object (evaluating 'm.parentNode.removeChild')`,
      /fetch failed$/,
      `Non-Error promise rejection captured with value: Timeout`,
      'cancelled',
      `m.parentNode is null`,
      'IndexSizeError',
      /Non-Error promise rejection captured with value: You don't have permissions/,
      /AbortError: The operation was aborted/,
      /'defineProperty' on proxy: trap returned falsish for property 'request'/,
      /Can't find variable: _AutofillCallbackHandler/,
    ],
    denyUrls: [
      /gtm-msr\.appspot\.com/,
      /a\.adroll\.com/,
      /trkcall\.com/,
      /script\.hotjar\.com/,
      /formobile\.com\.au/,
      /script\.hotjar\.com/,
      /maps\.googleapis\.com/,
      /google-analytics\.com/,
      /o\.clarity\.ms/,
      /regioner\.impel\.io/,
      /clarity\.ms/,
    ],
    initialScope: {
      tags: { client: true },
    },
    integrations: [new ExtraErrorData()],
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error instanceof DOMException && error.name === 'AbortError') {
        return null;
      }
      if (isNetworkError(error)) {
        return null;
      }
      return event;
    },
  });

  process.on('unhandledRejection', (err) => {
    Sentry.captureException({ message: 'error coming from _app', error: err });
  });

  process.on('uncaughtException', (err) => {
    Sentry.captureException({ message: 'error coming from _app', error: err });
  });
}
