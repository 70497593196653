import Log from '@/lib/Log';
import { makeMap, toTitleCase } from '@/lib/utils';

// eslint-disable-next-line @typescript-eslint/ban-types
export default class Filter<T extends {} = string | number | number[] | boolean> {
  static validTypes = [
    'query',
    'condition',
    'colour',
    'price',
    'priceRange',
    'body',
    'transmission',
    'year',
    'yearRange',
    'make',
    'model',
    'variant',
    'fuelType',
    'dealerID',
    'hotDeal',
    'photos',
    'listingType',
    'stockNo',
    'seats',
    'features',
    'prestigeDealerID',
    'hashtags',
  ];

  static listingTypes = ['New', 'Used', 'Demo'];

  type: string;
  value: T;
  extra: ExtraFilterInfo = {};
  locked = false; // Locked filters indicate that they should not be removable

  constructor(typeOrObject: string | MinimalFilterObject<T>, value?: T, extra: ExtraFilterInfo = {}, locked = false) {
    if (typeOrObject instanceof Object) {
      this.type = typeOrObject.type;
      this.value = typeOrObject.value;
      Object.assign(this, typeOrObject);
    } else {
      if (value === undefined) {
        throw new Error('Filter cannot be constructed - no value was provided.');
      }
      this.type = typeOrObject;
      this.value = value;
      this.extra = extra;
      this.locked = locked;
    }

    // Apply shortened label for make filters
    if (this.type === 'make' && !this.extra.label) {
      this.extra.label = makeMap(this.value.toString());
    }
  }

  get valid() {
    if (this.type?.length && !Filter.validTypes.includes(this.type)) {
      Log.error(`Invalid Filter type used: ${this.type}`, { filter: this });
    }

    return this.type?.length > 0 && typeof this.value !== 'undefined';
  }

  queryString = () => {
    switch (this.type) {
      case 'query':
        const query = typeof this.value === 'string' ? this.value.replace(/:/g, '') : this.value;
        return `query:${query}`;
      case 'model':
        if (this.extra.make && this.extra.model) {
          return `model:${this.extra.make}|${this.extra.model}`;
        }
        break;
      case 'variant':
        if (this.extra.make && this.extra.model && this.extra.variant) {
          return `variant:${this.extra.make}|${this.extra.model}|${this.extra.variant}`;
        }
        break;
      case 'prestigeDealerID':
        return ''; // shouldn't be a part of query string
      default:
        return `${this.type}:${this.value instanceof Array ? this.value.join('-') : this.value.toString()}`;
    }

    return null;
  };

  toString = (): string => {
    switch (this.type) {
      case 'price':
        return `Under: $${this.value.toLocaleString().split('.')[0]}`;
      case 'year':
        return `Year: ${this.value.toString()}`;
      case 'seats':
        return `Seats: ${this.value.toString()}`;
      case 'stockNo':
        return `Stock No: ${this.value.toString()}`;
      case 'make':
        return toTitleCase(makeMap(this.value.toString())) ?? '';
      case 'model':
      case 'variant':
        return toTitleCase(this.value.toString()) ?? '';
      case 'prestigeDealerID':
        return 'Prestige Vehicles';
      default:
        return this.extra?.label ?? this.value.toString() ?? '';
    }
  };

  static toQueryString(filters?: Filter[]) {
    return filters?.map((filter) => filter.queryString()).join(',') || '';
  }

  static isEqual(filter1: Filter<any>, filter2: Filter<any>) {
    return (
      filter1.type === filter2.type &&
      filter1.value === filter2.value &&
      filter1.extra?.label === filter2.extra?.label &&
      filter1.locked === filter2.locked
    );
  }
}
