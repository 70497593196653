import { stateToCityMap } from '@/lib/Cities';
import Log from '@/lib/Log';

const states: Record<string, string> = {
  'australian capital territory': 'ACT',
  act: 'ACT',
  'new south wales': 'NSW',
  nsw: 'NSW',
  'northern territory': 'NT',
  nt: 'NT',
  queensland: 'QLD',
  qld: 'QLD',
  'south australia': 'SA',
  sa: 'SA',
  tasmania: 'TAS',
  tas: 'TAS',
  VICTORIA: 'VIC',
  victoria: 'VIC',
  vic: 'VIC',
  'western australia': 'WA',
  'WESTERN-AUSTRALIA': 'WA',
  wa: 'WA',
};

const statesLong: Record<string, string> = {
  wa: 'western-australia',
  sa: 'south-australia',
  nsw: 'new-south-wales',
  qld: 'queensland',
  nt: 'northern-territory',
  tas: 'tasmania',
  vic: 'victoria',
  act: 'australian-capital-territory',
};

const stateOptions = ['WA', 'ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC'];
const servicingList = ['WA', 'VIC'].map((v) => ({ value: v, title: v }));
const statesList = stateOptions.map((v) => ({
  value: v,
  title: v,
}));

export { states, stateOptions, statesList, servicingList, statesLong };

/**
 * Attempts to normalize state into ISO 3166-2 abbreviation
 * @param state
 */
export function normalizeState(state: string) {
  const _state = trim(state.toLowerCase());
  if (_state && states[_state]) {
    return states[_state];
  } else {
    Log.error('Failed to normalize state', {
      _state,
    });
  }
  return '';
}

export function addressFormat(map: EtherMap) {
  if (map.parts) {
    const { number, address, city, county, state, postcode } = map.parts;
    if (number && address && (city || county) && state && postcode) {
      const shortState = states[state.toLowerCase()] ? states[state.toLowerCase()] : state;
      return [`${number} ${address}`, `${city || county} ${shortState} ${postcode}`].join('<br />');
    }
  }
  return map.address || '';
}

export function googleMapLink(map: EtherMap) {
  const { address, lat, lng } = map;
  if (address || (lat && lng)) {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address || `${lat},${lng}`)}`;
  }
  return '';
}

// Simple trim function to remove trailing/leading commas in addition to spaces
const trim = (value?: string | Maybe<string>) => {
  if (!value) return undefined;
  return value?.replace(/(^[,\s]+)|([,\s]+$)/g, '');
};

const extractStreetAddress = (addressParts: EtherMapParts) => {
  const { number, address } = addressParts;
  if (addressParts) {
    return number ? `${trim(number)}, ${trim(address)}` : trim(address);
  }
  return '';
};

const extractSuburb = (addressParts: EtherMapParts) => {
  return trim(addressParts.city) ?? trim(addressParts.county) ?? '';
};

const extractState = (addressParts: EtherMapParts, useStateAcronym = true) => {
  if (addressParts.state) {
    const { state } = addressParts;
    // Convert state to acronym
    const stateShort = normalizeState(state);

    if (stateShort) {
      if (useStateAcronym) {
        return stateShort;
      }
      // If need full state, use acronym to get statesLong mapping
      const stateLong = statesLong[stateShort.toLowerCase()];
      if (stateLong) {
        return stateLong
          .split('-')
          .map((f) => f[0].toUpperCase() + f.substring(1))
          .join(' ');
      }
    }

    return state;
  }

  return '';
};

export interface formatAddressOptions {
  /** display suburb?, default: `true` */
  suburb?: boolean;
  /** display city?, default: `false` */
  city?: boolean;
  /** display state?, default: `true` */
  state?: boolean;
  /** use state acronym instead of full state name, default: true */
  useStateAcronym?: boolean;
  /** seperate each locality, default: `,` */
  seperator?: string;
  /** fallback string to display if map data is insufficient */
  fallbackString?: string;
}

export function formatAddress(addressParts: Nullable<EtherMapParts>, options: formatAddressOptions = {}) {
  //  Set default options
  const opt: Required<formatAddressOptions> = {
    suburb: true,
    city: false,
    state: true,
    useStateAcronym: true,
    seperator: ',',
    fallbackString: 'at mymoto',
    ...options,
  };

  if (addressParts) {
    // const { city, county, state } = addressParts;
    const address: string[] = [];
    // add suburb (uses EtherMap city)
    const suburb = extractSuburb(addressParts);
    if (suburb && opt.suburb) address.push(suburb);

    // add city
    if (opt.city) {
      const nState = normalizeState(addressParts.state ?? '');
      if (nState) {
        const mappedCity = stateToCityMap[nState as keyof typeof stateToCityMap];
        if (mappedCity) address.push(mappedCity);
      }
    }

    // add state
    if (opt.state) {
      const state = extractState(addressParts, opt.useStateAcronym);
      if (state) address.push(state);
    }

    if (address.length) {
      return address.join(opt.seperator + ' ');
    }
  }

  return opt.fallbackString;
}

export function getAddressLines(addressParts: Nullable<EtherMapParts>) {
  const lines = {
    line1: '',
    line2: '',
    line3: '',
  };

  if (addressParts) {
    const street = extractStreetAddress(addressParts);
    const suburb = extractSuburb(addressParts);
    const state = extractState(addressParts, false);

    lines.line1 = [street, suburb].filter((f) => f).join(', ');
    lines.line2 = [trim(addressParts.postcode), state].filter((f) => f).join(', ');
    // Hardcoded, isn't a part of EtherMapParts
    lines.line3 = 'Australia';
  }

  return lines;
}

export function addressSuggestionOnSearch(address: string) {
  return address.replace(', Australia', '');
}
