const sortOptions = [
  { title: 'Featured', sortMethod: 'recommended' },
  { title: 'Just added', sortMethod: 'newest' },
  { title: 'Lowest price', sortMethod: 'price.asc' },
  { title: 'Highest price', sortMethod: 'price.desc' },
  { title: 'Lowest kms', sortMethod: 'mileage.asc' },
  { title: 'Highest kms', sortMethod: 'mileage.desc' },
  { title: 'Newest age', sortMethod: 'year.desc' },
  { title: 'Oldest age', sortMethod: 'year.asc' },
] as { title: string; sortMethod: SortMethod }[];

export default sortOptions;
