import getOrCreateStore from '@/lib/getOrCreateStore';
import AppState from '@/stores/AppState';
import DealershipsMap from '@/stores/DealershipsMap';
import DirectoryStore from '@/stores/DirectoryStore';
import Globals from '@/stores/Globals';
import PublicEnvironment from '@/stores/PublicEnvironment';
import ShortlistStore from '@/stores/ShortlistStore';

const getAppState = getOrCreateStore('appState', AppState);
const getDealershipsMap = getOrCreateStore('dealershipsMap', DealershipsMap);
const getGlobals = getOrCreateStore('globals', Globals);
const getDirectoryStore = getOrCreateStore('directoryStore', DirectoryStore);
const getShortlistStore = getOrCreateStore('shortlistStore', ShortlistStore);
const getPublicEnvironment = PublicEnvironment.getInstance;

export { getAppState, getDealershipsMap, getGlobals, getDirectoryStore, getShortlistStore, getPublicEnvironment };
